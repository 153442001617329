<template>
  <table ref="mainTable" :class="{'main-table': !enableXScroll, 'main-table-scroll': enableXScroll}" :style="{'background-color': tableBGColor, 'color': tableColor, 'overflow': 'hidden'}">
    <colgroup>
      <col v-if="!hideCheckboxCol" style="width: 1.75em;">
      <col v-if="!hideFolderCol" style="width: 1.75em;">
      <template v-for="(col, index) in tableData.cols" >
        <col v-if="col.type === 'icon' && !col.hide" style="width: 2em;">
        <col v-else-if="(col.type === 'html' || col.type === 'component') && !col.hide" :style="{'width': col.width || 'auto' }">
        <col v-else-if="col.type ==='date' && !col.hide" :style="dateColStyle">
      </template>
      <col v-if="tableData.showRightMenu" style="width: 2.3em;">
    </colgroup>
    <thead v-if="tableData.showHeader" v-show="showDataRows">
      <tr>
        <th v-if="tableData.showCheckbox" :class="tableCellClasses" :style="{'font-weight': headerFontWeight}" style="font-size: 12px; display: flex; align-items: center; justify-content: center;">
          <span
              v-if="!allSelected"
              @click="checkboxHeaderClick"
              class="fal fa-square ribbon-icon"
          ></span>
          <span
              v-else
              @click="checkboxHeaderClick"
              class="fas fa-check-square ribbon-icon"
          ></span>

        </th>
        <th v-else-if="!hideCheckboxCol" :class="tableCellClasses" :style="{'font-weight': headerFontWeight}"></th>
        <th v-if="!hideFolderCol" :class="tableCellClasses" :style="{'font-weight': headerFontWeight}">
                      <span
                          v-if="folderHeaderCol"
                          @click="folderHeaderClick"
                          class="ribbon-icon"
                          :class="folderHeaderCol.cssClass"
                          {{folderHeaderCol.html}}
                      ></span>

        </th>
        <template v-for="(col, index) in tableData.cols">
          <template v-if="col">
            <th :style="{'font-weight': headerFontWeight}" :ref="'tableHeaderCol_' + index" v-if="col.header && col.header.type === 'html'  && !col.hide" :colspan="col.colspan || 1" :class="tableCellClasses">
              <span @click="col.callback(col)" v-tooltip="{content: col.header.tooltip || ''}" v-html="col.header.html"></span>
            </th>
            <th :style="{'font-weight': headerFontWeight}" :ref="'tableHeaderCol_' + index" :colspan="col.colspan || 1" v-else-if="!col.hide" >
              <span v-if="col.header" @click="col.callback(col)" v-tooltip="{content: col.tooltip || ''}" v-html="col.header"></span>
            </th>
          </template>

      </template>
        <th :style="{'font-weight': headerFontWeight}" v-if="tableData.showRightMenu && ((typeof tableData.showHeaderRightMenu !== 'undefined') ? tableData.showHeaderRightMenu : true)"
            :class="tableCellClasses">
                      <span
                          @click="rightMenuHeaderClick"
                          class="far fa-ellipsis-v ribbon-icon"
                          style="padding-right: 7px;"
                      ></span>
        </th>
      </tr>
    </thead>
    <template v-for="(dataRow, index) in tableData.dataRows">
      <tr :id="dataRow.path" :key="index" :ref="'tableRow' + index" :class="tableRowClasses" :style="rowStyle" v-show="showDataRows && dataRow.show" v-if="1">
        <td v-if="(tableData.showCheckbox || dataRow.showCheckbox) && !dataRow.hideCheckbox" :class="tableCellClasses" style="font-size: 12px; vertical-align: top;">
              <span v-if="dataRow.selected"
                    v-on="(dataRow.checkboxCol.settings && dataRow.checkboxCol.settings.disabled) ? ( dataRow.checkboxCol.settings.disabledCallback ? { click: dataRow.checkboxCol.settings.disabledCallback.bind(null, dataRow, index ) } : null) : { click: checkboxClick.bind(null, dataRow, index ) }"
                    class="fas fa-check-square ribbon-icon"
                    style="padding-left:5px; "
                    :class="{'grayout': (dataRow.checkboxCol.settings && dataRow.checkboxCol.settings.disabled)}"
                    :ref="'checkboxIcon_' + index"

              ></span>
          <span v-else
                v-on="(dataRow.checkboxCol.settings && dataRow.checkboxCol.settings.disabled) ? ( dataRow.checkboxCol.settings.disabledCallback ? { click: dataRow.checkboxCol.settings.disabledCallback.bind(null, dataRow, index ) } : null) : { click: checkboxClick.bind(null, dataRow, index ) }"
                class="fal fa-square ribbon-icon"
                style="padding-left:5px; "
                :class="{'grayout': (dataRow.checkboxCol.settings && dataRow.checkboxCol.settings.disabled)}"
                :ref="'checkboxIcon_' + index"

          ></span>
        </td>
        <td v-else-if="dataRow.checkboxCol.alt" :class="tableCellClasses" :colspan="dataRow.checkboxCol.alt.colspan || 1" :style="dataRow.checkboxCol.alt.styleObj || {}">
          <span v-if="dataRow.checkboxCol.alt.type === colTypes.html || dataRow.checkboxCol.alt.type === colTypes.fixed" data-sid="html" @click="dataRow.checkboxCol.alt.callback(dataRow)" v-tooltip="{content: dataRow.checkboxCol.alt.tooltip || ''}" v-html="dataRow.checkboxCol.alt.html"></span>
          <component  v-else-if="dataRow.checkboxCol.alt.type === colTypes.component" v-bind:is="dataRow.checkboxCol.alt.componentName" v-model="dataRow.checkboxCol.alt.value"  v-bind="dataRow.checkboxCol.alt.props" v-bind:tableData="tableData" v-bind:dataRow="dataRow"></component>
        </td>
        <td v-else-if="!hideCheckboxCol"></td>
        <td v-if="tableData.showFolder && dataRow.showFolder" :class="tableCellClasses" style="overflow: visible;" :style="dataRow.folderCol.styleObj || {}">
                    <span style="display: inline-grid;">
                        <span v-if="dataRow.level > 1" class="fas fa-caret-down" style="position: relative; grid-column: 1; grid-row: 1;" :style="dataRow.folderIndicatorStyle"></span>
                          <span v-if="dataRow.expanded"
                                v-on="(dataRow.folderCol.settings && dataRow.folderCol.settings.disabled) ? null : { click: folderClick.bind(null, dataRow, index ) }"
                                class="far fa-angle-down ribbon-icon"
                                v-tooltip="{content: 'Collapse'}"
                                :style="folderIconPaddingStyle"
                                style="grid-column: 1; grid-row: 1; font-weight: bold;padding-left:5px;"

                          ></span>
                          <span v-else
                                v-on="(dataRow.folderCol.settings && dataRow.folderCol.settings.disabled) ? null : { click: folderClick.bind(null, dataRow, index ) }"
                                class="far fa-angle-right ribbon-icon "
                                v-tooltip="{content: 'Expand'}"
                                :style="folderIconPaddingStyle"
                                style="grid-column: 1; grid-row: 1; font-weight: bold;padding-left:5px;"
                          ></span>
                    </span>
        </td>
        <td :class="tableCellClasses" v-else-if="dataRow.folderCol.alt" :colspan="dataRow.folderCol.alt.colspan || 1" :style="dataRow.folderCol.alt.styleObj || {}"
            @click="dataRow.folderCol.alt.callback(dataRow)">
          <span v-tooltip="{content: dataRow.folderCol.alt.tooltip || ''}" v-html="dataRow.folderCol.alt.html"></span>
        </td>
        <template v-else-if="hideFolderCol || dataRow.noFolderCol"></template>
        <td v-else :class="tableCellClasses" style="overflow: visible;" >
                    <span style="display: inline-grid;">
                        <span v-if="dataRow.level > 1" class="fas fa-caret-down" style="position: relative; grid-column: 1; grid-row: 1;" :style="dataRow.folderIndicatorStyle"></span>
                    </span>
        </td>
        <template v-for="(col, colIndex) in dataRow.cols">

          <template v-if="tableData.cols[colIndex] && !tableData.cols[colIndex].hide" >
            <td
                v-if="col && !col.hide && (col.type === 'component')"

                :colspan="col.colspan || 1"
                :class="tableCellClasses"
                :style="col.styleObj || {}"
                :ref="'col_' + index + '_' + colIndex"
            >
              <component
                  :ref="'colComponent_' + index + '_' + colIndex"
                  v-model="col.value" v-bind:is="col.componentName"
                  v-on="col.eventHandlers"
                  v-bind="col.props" v-bind:tableData="tableData" v-bind:dataRow="dataRow"></component>
            </td>
            <template v-else>
              <td
                  v-if="(typeof col.tooltip === 'string') || (typeof col.tooltip === 'undefined')"
                  v-on=" (!col.noCallback && col.callback) ? { click: (ev) => {
                  colClickCallback(ev, col, dataRow, index);

                }} : {}"
                  :style="col.styleObj || {}"
                  style="cursor: pointer;"
                  :data-type="col.type"
                  :colspan="col.colspan || 1"
                  :class="tableCellClasses"

                  :ref="'col_' + index + '_' + colIndex"
                  v-html="col.html"
                  v-tooltip="{content: col.tooltip || ''}"
              ></td>
              <td
                  v-else
                  v-on=" (!col.noCallback && col.callback) ? { click: (ev) => {
                  colClickCallback(ev, col, dataRow, index);

                }} : {} "
                  :style="col.styleObj || {}"
                  style="cursor: pointer;"
                  :data-type="col.type"
                  :colspan="col.colspan || 1"
                  :class="tableCellClasses"

                  :ref="'col_' + index + '_' + colIndex"
                  v-html="col.html"
                  @mouseover="showColTooltip($event, col, dataRow, index)"
                  @mouseleave="hideColTooltip($event, col, dataRow, index)"
              ></td>
            </template>

          </template>
        </template>
        <td v-if="tableData.showRightMenu && !dataRow.noRightMenuCol" :class="tableCellClasses" style="vertical-align:top;"  >
                      <span
                          @click.self="rightMenuClick(dataRow, index, $event)"
                          v-if="dataRow.showRightMenu"
                          class="far fa-ellipsis-v ribbon-icon"
                          style="padding-right: 7px;"
                          :ref="rmIconPrefix + index"

                      ></span>
          <span v-else></span>
        </td>

      </tr>
      <tr :ref="'tableSecondRow_' + index" v-if="dataRow.secondRowComponent" v-show="dataRow.show && dataRow.showSecondRow" class="second-row">
        <template v-if="secondRowLine">
          <td  class="second-row-cell"></td>
          <td :ref="'second_row_second_cell_' + index"></td>
          <td :colspan="colspanVal - 2" class="second-row-component-container">
            <component
                :ref="'secondRow_' + index"
                v-if="dataRow.secondRowComponent"
                v-bind:tableData="tableData"
                v-bind:dataRow="dataRow"
                v-bind:is="dataRow.secondRowComponent"
                v-bind="dataRow.secondRowProps"
            >
            </component>
          </td>

        </template>

        <td v-else :colspan="colspanVal" :style="dataRow.secondRowCellStyle || {}" >
          <component

              :ref="'secondRow_' + index"
              v-if="dataRow.secondRowComponent"
              v-bind:tableData="tableData"
              v-bind:dataRow="dataRow"
              v-bind:is="dataRow.secondRowComponent"
              v-bind="dataRow.secondRowProps"
          >
          </component>
        </td>

      </tr>
    </template>
  </table>
</template>

<script setup>
  import { onMounted, reactive, ref, computed } from 'vue';
  import * as oxygenUtil from './../OxygenUtil/oxygenutil.js';
  import { vTooltip,createTooltip, destroyTooltip } from 'floating-vue';



  let props = defineProps({
    activeSelection: {
      type: Boolean,
      default: true
    },
    folderIconPaddingStyle: {
      type: Object,
      default() {
        return {};
      }
    },
    rowStyle: {
      type: Object,
      default() {
        return {};
      }
    },
    textColIndex: {
      type: Number,
      default: 1
    },
    enableXScroll: {
      type: Boolean,
      default: false
    },
      descData: {
        type: Boolean,
        default: true
      },
      folderHeaderCol: {
        type: Object,
        default: null
      },
      tableData: Object,
      singleSelect: {
        type: Boolean,
        default: false
      },
      hideCheckboxCol: {
        type: Boolean,
        default: false
      },
      hideFolderCol: {
        type: Boolean,
        default: false
      },
      tableBGColor: {
        type: String,
        //default: 'rgb(248, 248, 248)'
        default: 'white'
      },
      tableColor: {
        type: String,
        default : 'black'
      },
      checkboxHeaderCallback: Function,
      rightMenuHeaderCallback: Function,
      folderHeaderCallback: Function,
      tableCellNowrap: {
        type: Boolean,
        default: false
      }
    });

  let headerFontWeight = ref('400');

  let tableCellClasses = ref({
    'enable-x-scroll': props.enableXScroll,
    'menu-table-cell': !props.tableCellNowrap,
    'menu-table-cell-nowrap': props.tableCellNowrap
  });

  let tableRowClasses = ref({
    'oxygen-menu-table-row': props.activeSelection
  });

  let emit = defineEmits(['row-selected', 'row-deselected']);

  const secondRowLine = ref('true');

  const mainTable = ref(null);
  const dateColStyle = computed(() => {
    if(mainTable.value) {
      let tableRect = mainTable.value.getBoundingClientRect();
      if(tableRect.width > 415) {
        return {
          width: '20em'
        }
      }
      else {
        return {
          width: '4em'
        }
      }
    }
  });

  let tooltipsMap = new Map();
  function showColTooltip(ev, col, dataRow, index) {
    let target = ev.target;
    //console.log('showColTooltip', 'ev:', ev, ' - target:', ev.target);
    let tipObj;

    //console.log('showColTooltip', 'mapE:', tooltipsMap.get(ev.target));

    let content = (tipObj = col.tooltip.find(e => target.classList.contains(e.class))) ? tipObj.content : '';
    let tooltip = createTooltip(target, {
        triggers: [],
        content
      });

    if(content) {
      let current;
      if(current = tooltipsMap.get(col)) {
        current.tooltip.hide();
        destroyTooltip(current.target);
      }
      tooltipsMap.set(col, { tooltip, target });

      //console.log('showColTooltip', 'mapE:', tooltipsMap.get(target));

      //console.log('showColTooltip', 'tip:', tooltip);

      //tooltipsMap.set(ev.target, tooltip);

      tooltip.show();
    }
  }

  function hideColTooltip(ev, col, dataRow, index) {
    let current;
    //console.log('hideColTooltip', 'target:', ev.target, ' - tooltip:', tooltipsMap.get(target));
    if(current = tooltipsMap.get(col)) {
      console.log('hideColTooltip', 'hiding and destorying');
      current.tooltip.hide();
      destroyTooltip(current.target);
    }
  }

  function colClickCallback(ev, col, dataRow, index) {
    //console.log('click-callback', 'dataRow:', dataRow, ' - ev.target:', ev.target, ' - ev:', ev);
    col.callback(dataRow, index, ev, ev.target);
  }

  const colspanVal = computed(() => {
    return (props.tableData ? props.tableData.cols.filter(e => (!e.hide)).length : 0) + 2 + (props.tableData ? (props.tableData.showRightMenu ? 1 : 0) : 0);
  });



    onMounted(() => {
      //console.log('onMounted', 'called');

      if(!props.tableData.dataRows) {
        //$set(props.tableData, 'dataRows', []);
        props.tableData['dataRows'] = [];
      }
      else {
        reactive(props.tableData.dataRows);
      }
    });

    let pathMap = reactive({}), showDataRows = ref(true), checkboxHeaderClass = reactive({
      'fal': true,
      'fa-square': true,
      'fas': false,
      'fa-check-square': false
    }), allSelected = ref(false), selectedRows = ref([]), rmIconPrefix = 'rightMenuIcon_';


      function resetTable() {
        props.tableData.dataRows = [];
        selectedRows.value = [];
        pathMap = {};
      }

      function addRow(row, index) {
        let fixedCols = {
          'checkboxCol': {
            cssClass: {}
          },
          'folderCol': {
            cssClass: {}
          },
          'rightMenuCol': {
            cssClass: { 'far': true, 'fa-ellipsis-v': true}
          }
        };

        unsetCheckboxObject(fixedCols.checkboxCol.cssClass);
        setExpandFolderIcon(fixedCols.folderCol.cssClass);


        for(let i in fixedCols) {
          row[i] = Object.assign(row[i] || {}, fixedCols[i], row[i] || {});

          //if(!row[i]) {
            //$set(row, i, fixedCols[i]);
          //  row[i] = fixedCols[i];
          //}
        }



        /*
        row.cols.filter(e => e.html).forEach((e)=>{
          e.htmlComponent = compile(e.html);
        });
        */






        if(typeof row.showFolder === "undefined") {
          //$set(row, 'showFolder', false);
          row['showFolder'] = false;
        }

        if(typeof row.showRightMenu === "undefined") {
          //$set(row, 'showRightMenu', true);
          row['showRightMenu'] = true;
        }

        //$set(pathMap, row.path, { row: row, children: [] });
        pathMap[row.path] = { row: row, children: [] };
        let exp = /(.+)\/.+$/;
        let matches = exp.exec(row.path);
        if(matches && pathMap[matches[1]]) {
          if(viewMode.value === viewModes.folder && !pathMap[matches[1]].row.expanded) {
            row.show = false;
          }
          pathMap[matches[1]].children.push(row);
          pathMap[matches[1]].row.showFolder = true;
        }

        if(typeof row.show === "undefined") {
          //$set(row, 'show', true);
          row['show'] = true;
        }

        //$set(row, 'level', row.path.split('/').length);
        row['level'] = row.path.split('/').length;
        //$set(row, 'expanded', false);
        row['expanded'] = false;
        //$set(row, 'selected', (typeof row.selected !== "undefined") ? row.selected : false);
        row['selected'] = (typeof row.selected !== "undefined") ? row.selected : false;

        let parentRowIndex;
        if(row.threadEvent && ((parentRowIndex = getRowIndex(row.parentId)) !== -1)) {
          index = parentRowIndex + (props.descData ? 1 : 0);
        }

        if(!row.folderIndicatorStyle) {
          row.folderIndicatorStyle = {left: getFolderIndicatorLeftPos(row), top: '-18px' };
        }

        insertRowInTable(row, index);
      }

      const viewModes =  {
        folder: 'folder',
        sort: 'sort',
        normal: 'normal',
        template: 'template'
      }, viewMode = ref('folder'), createdPaths = ref({});

  function insertRowInTable(row, index) {
    //this.parent.debugLog('insertRowInTable', 'row:', row, ' - index:', index);
    let viewModeFn = {
      [viewModes.sort]: insertRowInSortMode,
      [viewModes.folder]: insertRowInFolderMode,
      [viewModes.normal]: insertRowInNormalMode,
      [viewModes.template]: insertRowInTemplateMode
    };
    viewModeFn[viewMode.value](row, index);
  }

  function insertRowInTemplateMode(row) {
    let newRows = createTemplateViewRows([row]);
    props.tableData.dataRows.push(...newRows);
    sortRowsByPath(props.tableData.dataRows);
  }

  const sortColIndex = ref(''),
      sortAscMode = ref(true);

  function insertRowInSortMode(row) {
    let arrayCopy = [...props.tableData.dataRows];
    arrayCopy.push(row);
    sortForSortView(arrayCopy, sortColIndex.value, sortAscMode.value);
  }

  function insertRowInFolderMode(row) {
    let arrayCopy = [...props.tableData.dataRows];
    arrayCopy.push(row);
    sortForFolderView(arrayCopy);
    props.tableData.dataRows = arrayCopy;
  }

  function sortForFolderView(arr) {
    sortWithParam(arr, 'path');
  }

  function sortForNormalView(arr) {
    sortWithParam(arr, 'normalViewIndex');
  }

  function sortWithParam(arr, paramName) {
    arr.sort((a,b)=> {
      if(a[paramName] < b[paramName]) {
        return -1;
      }
      if(a[paramName] > b[paramName]) {
        return 1;
      }
      return 0;
    });
  }

  function sortRowsByPath(rows) {
    rows.sort((a, b)=>{
      let nameA = a.path.toUpperCase(); // ignore upper and lowercase
      let nameB = b.path.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  const colTypes = reactive({
    date: 'date',
    html: 'html',
    icon: 'icon',
    fixed: 'fixed',
    component: 'component',
    mixed: 'mixed'
  });

  function sortForSortView(arr, sortColIndex, ascending) {
    if(props.tableData.cols[sortColIndex] && props.tableData.cols[sortColIndex].type && (props.tableData.cols[sortColIndex].type === colTypes.date)) {
      doDateSort(arr, sortColIndex, ascending);
    }
    else {
      doStringSort(arr, sortColIndex, ascending);
    }
  }

  function doStringSort(arr, sortColIndex, ascending) {
    //this.parent.debugLog('doStringSort', 'ascending:', ascending);
    arr.sort((a, b)=> {
      if((typeof a.cols[sortColIndex].sortParam !== "undefined") && (typeof b.cols[sortColIndex].sortParam !== "undefined")) {
        let dataA = `${a.cols[sortColIndex].sortParam}`.toUpperCase(); // ignore upper and lowercase
        let dataB = `${b.cols[sortColIndex].sortParam}`.toUpperCase(); // ignore upper and lowercase

        //this.parent.debugLog('doStringSort', 'dataA:', dataA, ' - dataB:', dataB);
        //this.parent.debugLog('doStringSort', 'dataA < dataB:', (dataA < dataB), ' - dataB:', dataB);

        if (dataA < dataB) {
          return ascending ? -1 : 1;
        }
        if (dataA > dataB) {
          return ascending ? 1 : -1;
        }
      }
      return 0;
    });

  }

  function doDateSort(arr, sortColIndex, ascending) {
    arr.sort((a, b)=> {
      if(a.cols[sortColIndex].value && b.cols[sortColIndex].value) {
        if(ascending) {
          return new Date(a.cols[sortColIndex].value) - new Date(a.cols[sortColIndex].value);
        }
        else {
          return new Date(b.cols[sortColIndex].value) - new Date(b.cols[sortColIndex].value);
        }
      }
      return 0;
    });
  }

  function colOffset(dataRow, colIndex) {
    let colspanTotal = 0;
    for(let i = colIndex - 1; i >= 0; i--) {
      if(dataRow.cols[i].colspan) {
        colspanTotal += parseInt(dataRow.cols[i].colspan) - 1;
      }
    }
    return colspanTotal;
  }


  function createTemplateViewRows(dataRows) {
    let newDataRows = [];

    dataRows.forEach((row)=>{
      let parsedPathOES = oxygenUtil.parseOES(row.fullTemplatePath).segments, currentSegmentPath = '';
      parsedPathOES.forEach((pathSegment, index)=>{

        if(index < (parsedPathOES.length - 1)) {
          currentSegmentPath = currentSegmentPath + (currentSegmentPath ? '/' : '') + pathSegment;
          if(!createdPaths[currentSegmentPath]) {
            createdPaths[currentSegmentPath] = 1;
            let newRow = {
              cols: [],
              path: currentSegmentPath,
              showFolder: true,
              templateViewRow: true,
              templateViewLevel: index,
              pathSegment,
              leafRow: row,
              show: ( index === 0 ) ? true : false
            };
            row.cols.forEach((col, colIndex)=>{
              let colType = props.tableData.cols[colIndex + colOffset(row, colIndex)].type;
              if(colType === colTypes.icon) {
                newRow.cols.push({html: '', callback: ()=>{}});
              }
              else if(colType === colTypes.date) {
                newRow.cols.push({value: '', callback: ()=>{}});
              }
              else if([colTypes.html, colTypes.fixed, colTypes.mixed].includes(colType)) {
                let col = { html: '', callback: ()=>{}}
                if(colIndex === props.textColIndex) {
                  col.html = `<span  v-new-tooltip="{ content: '${oxygenUtil.escapeHTMLEntities(pathSegment)}', show: false, trigger: 'hover' }">${oxygenUtil.encodeHTMLEntities(oxygenUtil.formatDN(pathSegment))}</span>`
                }
                newRow.cols.push(col);
              }
              else {
                newRow.cols.push({callback:()=>{}});
              }
            });
            newDataRows.push(newRow);
          }
        }
      });
      row.normalShowValue = row.show;
      row.normalShowFolder = row.showFolder;
      row.normalPath = row.path;
      row.normalLevel = row.level;

      row.show = false;
      row.showFolder = false;
      row.path = `${currentSegmentPath}/${row.normalPath}`;
      newDataRows.push(row);
    });
    sortRowsByPath(newDataRows);
    return newDataRows;
  }



  function insertRowInNormalMode(row, index) {
        if((typeof index === "undefined") || index >= props.tableData.dataRows.length) {
          props.tableData.dataRows.push(row);
        }
        else {
          props.tableData.dataRows.splice(index, 0, row);
        }
      }

      function rightMenuClick(dataRow, index, event) {
        //console.log('rightMenuClick', 'event:', event, ' - target:', event.target);
        if((!dataRow.rightMenuCol.settings || (dataRow.rightMenuCol.settings && !dataRow.rightMenuCol.settings.disabled)) && dataRow.rightMenuCallback) {
          dataRow.rightMenuCallback(dataRow, index, event, event.target);
        }
      }
      
      function expandFolder(dataRow) {
        pathMap[dataRow.path].children.forEach((row)=>{
          row.show = true;
        });
      }
      
      function collapseFolder(dataRow) {
        if(pathMap[dataRow.path] && Array.isArray(pathMap[dataRow.path].children) && pathMap[dataRow.path].children.length) {
          pathMap[dataRow.path].children.forEach((row) => {
            setExpandFolderIcon(row.folderCol.cssClass);
            row.expanded = false;
            row.show = false;
            collapseFolder(row, false);
          });
        }
      }

      function folderClick(dataRow, index) {
        if(!dataRow.underProgress) {
          dataRow.expanded = !dataRow.expanded;
          if(dataRow.expanded) {

            setCollapseFolderIcon(dataRow.folderCol.cssClass);

          }
          else {

            setExpandFolderIcon(dataRow.folderCol.cssClass);
          }

          if(dataRow.folderCol.callback && !dataRow.folderCol.cbDisabled) {
            dataRow.folderCol.callback(dataRow, index, dataRow.expanded);
          }
          else {
            defaultFolderClickHandler(dataRow);
          }

        }
      }

      function defaultFolderClickHandler(dataRow) {
        if(!dataRow.hasSiblings) {
          if(dataRow.expanded) {
            expandFolder(dataRow);
          }
          else {
            collapseFolder(dataRow, true);
          }
        }
        else {
          let newPath;
          if(dataRow.expanded) {
            let row = getRow(dataRow.threadChildId), currentParent, currentParentRow;
            if(row) {
              currentParent = row.parentId;
            }
            if (newPath = reparentRows(dataRow.threadChildId, dataRow.path)) {
              if(currentParentRow = getRow(currentParent)) {
                currentParentRow.threadChildId = newPath;
              }
              dataRow.threadChildId = newPath;
              expandFolder(dataRow);
            }
          }
          else {
            collapseFolder(dataRow, true);
          }
        }
      }

      function reparentRows(rowPath, newParentPath) {
        let row;
        if(row = getRow(rowPath)) {
          collapseFolder(row.parentId);
          let nodePath = /^.+\/(.+)$/, results;
          if(results = nodePath.exec(rowPath)) {
            deleteRow(row.path);
            row.path = `${newParentPath}/${results[1]}`;
            row.parentId = newParentPath;
            addRow(row);
            if(row.threadChildId) {
              let newPath;
              if(newPath = reparentRows(row.threadChildId, row.path)) {
                row.threadChildId = newPath;
              }
            }
            return row.path;
          }
        }
      }

     function deleteRow(path) {
        let index = props.tableData.dataRows.findIndex(e => (e.path === path));
        if(index !== -1) {
          delete pathMap[path];
          props.tableData.dataRows.splice(index, 1);
        }
      }

      function getFolderIndicatorLeftPos(dataRow){
        return (dataRow.level - 1)*10 + 'px';
      }

      function calculateFolderIndicatorTopPos(x) {
        let val = Math.round(12.63939754525707+(-0.4972269501471537*x)-Math.cos(-1.139055581362362*x))
        return `${val}px`;
      }

      function displayDataRows() {
        showDataRows.value = true;
      }

      function hideDataRows(){
        showDataRows.value = false;
      }

      function rightMenuHeaderClick() {
        if(rightMenuHeaderCallback) {
          rightMenuHeaderCallback();
        }
        else {
          defaultRightMenuHeaderCallback();
        }
      }

      function defaultRightMenuHeaderCallback() {
      }

      function folderHeaderClick() {
        if(folderHeaderCallback) {
          folderHeaderCallback();
        }
      }

      function checkboxHeaderClick() {
        if(checkboxHeaderCallback) {
          checkboxHeaderCallback();
        }
        else {
          defaultCheckboxHeaderCallback();
        }
      }

      function defaultCheckboxHeaderCallback() {
        allSelected.value = !allSelected.value;
        setAllCheckboxes(allSelected.value);
      }

      function setAllCheckboxes(val) {
        if(val) {
          setCheckboxObject(checkboxHeaderClass);
        }
        else {
          unsetCheckboxObject(checkboxHeaderClass);
        }

        props.tableData.dataRows.forEach((dataRow, index) => {
          checkboxClick(dataRow, index, {}, val);
        });
      }

      function setCheckboxObject(chkObj) {
        chkObj['fal'] = chkObj['fa-square'] = false;
        chkObj['fas'] = chkObj['fa-check-square'] = true;

      }

      function unsetCheckboxObject(chkObj) {
        chkObj['fas'] = chkObj['fa-check-square'] = false;
        chkObj['fal'] = chkObj['fa-square'] = true;
      }

      function setExpandFolderIcon(obj) {
        obj['far'] = obj['fa-angle-right'] = true;
        obj['fa-angle-down'] = false;
      }

      function setCollapseFolderIcon(obj) {
        obj['far'] = obj['fa-angle-down'] = true;
        obj['fa-angle-right'] = false;
      }

      function checkboxClick(dataRow, index, ev, val) {
        toggleCheckbox(dataRow, index, ev, val);
        if(dataRow.checkboxCol && dataRow.checkboxCol.callback) {
          dataRow.checkboxCol.callback(dataRow, index);
        }
      }

      let lastSelectedRowIndex = ref(null);

      function toggleCheckbox(dataRow, index, ev, val) {
        dataRow.selected = (typeof val !== "undefined") ? val : !dataRow.selected;

        if(dataRow.selected) {
          setCheckboxObject(dataRow.checkboxCol);
          if(props.singleSelect && selectedRows.value.length) {
            deselectRow(selectedRows.value[0].path);
          }
          else {
            if (ev && ev.shiftKey && lastSelectedRowIndex) {
              //selectMultipleRows(lastSelectedRowIndex, index);
              lastSelectedRowIndex = null;
            } else {
              lastSelectedRowIndex = index;
            }
          }
          selectedRows.value = props.tableData.dataRows.filter(e => e.selected);
          emit('row-selected', dataRow, index);
        }
        else {

          lastSelectedRowIndex = null;
          unsetCheckboxObject(dataRow.checkboxCol);
          selectedRows.value = props.tableData.dataRows.filter(e => e.selected);
          emit('row-deselected', dataRow, index);
        }
      }

      function deselectRow(path) {
        let row = getRow(path);
        if(row) {
          let index = props.tableData.dataRows.findIndex( e => e === row );
          checkboxClick(row, index, {}, false);
          return row;
        }
        return null;
      }

      function selectRow(path) {
        let row = getRow(path);
        if(row) {
          let index = props.tableData.dataRows.findIndex( e => e === row );
          checkboxClick(row, index, {}, true);
          return row;
        }
        return null;
      }

      function getRow(path) {
        return props.tableData.dataRows.find(e => e.path === path);
      }

      function getRowIndex(path) {
        return props.tableData.dataRows.findIndex(e => e.path === path);
      }

      function getPathRows(pathPrefix) {
        return props.tableData.dataRows.filter(e => e.path.startsWith(pathPrefix));
      }

      function deletePathRows(pathPrefix) {
        props.tableData.dataRows = props.tableData.dataRows.filter((row)=>{
          if(row.path.startsWith(pathPrefix)){
            delete pathMap[row.path];
            return false;
          }
          return true;
        });
      }

      function getSelectedRows() {
        return props.tableData.dataRows.filter(e => e.selected);
      }

      function getSelectedPathRows(pathPrefix) {
        return getSelectedRows().filter((row)=>{
          return row.path.startsWith(pathPrefix);
        });
      }

      function getSelectedPathRowsWithIndex(pathPrefix) {
        return getSelectedRows().reduce((acc, row, i)=>{
          if(row.path.startsWith(pathPrefix) && row.show) {
            acc.push({row, i});
          }
          return acc;
        },[]);
      }

      function getPathRowByAttrValue(pathPrefix, attr, value) {
        for(let i = 0; i < props.tableData.dataRows.length; i++) {
          if(props.tableData.dataRows[i].path.startsWith(pathPrefix) &&
              props.tableData.dataRows[i][attr] &&
              props.tableData.dataRows[i][attr] === value) {
            return props.tableData.dataRows[i];
          }
        }
        return null;
      }

      function setFolderView() {
        viewMode.value = viewModes.folder;
        sortForFolderView(props.tableData.dataRows);
      }

  function setTemplateView() {
    props.tableData.normalDataRows = props.tableData.dataRows;
    props.tableData.dataRows = [];
    createTemplateViewRows(props.tableData.normalDataRows).forEach((row)=>{
      addRow(row);
    });
    viewMode.value = viewModes.template;
  }


  function setNormalView() {

    if(props.tableData.normalDataRows) {
      props.tableData.normalDataRows.forEach((e)=>{
        e.path = e.normalPath;
        e.show = e.normalShowValue;
        e.showFolder = e.normalShowFolder;
        e.level = e.normalLevel;
      })
      props.tableData.dataRows = props.tableData.normalDataRows;
    }
    viewMode.value = viewModes.normal;
  }

  const preFilterRows = ref(null);

  const viewFn = {
    folder: 'setFolderView',
    template: 'setTemplateView',
    normal: 'setNormalView'
  }

  defineExpose({
    calculateFolderIndicatorTopPos,
    getFolderIndicatorLeftPos,
    addRow,
    tableData: props.tableData,
    resetTable,
    folderClick,
    hideDataRows,
    expandFolder,
    collapseFolder,
    deselectRow,
    selectRow,
    getPathRows,
    pathMap,
    deletePathRows,
    displayDataRows,
    getSelectedRows,
    getSelectedPathRows,
    getSelectedPathRowsWithIndex,
    getPathRowByAttrValue,
    setFolderView,
    setNormalView,
    setTemplateView,
    showDataRows,
    getRow,
    getRowIndex,
    viewMode,
    viewModes,
    preFilterRows,
    viewFn,
    deleteRow,
    reparentRows
  });

</script>

<style scoped>
@import 'node_modules/floating-vue/dist/style.css';
/*@import url('/simplia/dist/css/floating-vue-style.css');*/



td.menu-table-cell, th.menu-table-cell {
  padding: 0 5px;
  font-size: inherit;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

td.menu-table-cell-nowrap, th.menu-table-cell-nowrap {
  padding: 0 5px;
  font-size: inherit;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

td.enable-x-scroll, th.enable-x-scroll {
  /*min-width: 50px;*/
}

.ribbon-icon { cursor: pointer; }

.oxygen-menu-table-row:active {
  background-color: grey !important;
}

table td, table th {
  min-height: 39px;
}

.main-table-scroll {
  width: 100%;
}

.main-table {
  width: 100%;
  table-layout: fixed;
}

.grayout {
  opacity: 0.6; /* Real browsers */
  filter: alpha(opacity = 60); /* MSIE */
  cursor: default;
}

.second-row {
  /*background-color: rgb(248, 248, 248);*/
  background-color: white;
  color: black;
}
/*
.second-row-cell:after {
  content: "";
  background-color: #0072C5;
  position: relative;
  width: 0.22em;
  height: 112%;
  top: -0.3em;
  left: -0.34em;
  display: block;
}

 */

.second-row-component-container {
  padding: 0;
}
</style>
<template>

  <oxygen-menu-table
      :row-style="rowStyle"
    v-bind:tableData="tableData"    
    ref="menuTable"
    v-bind:folderHeaderCol="folderHeaderCol"
    v-on:row-selected="dataRowSelected"
    v-on:row-deselected="dataRowDeselected"
    v-on:selected-rows-changed="selectedRowsChanged"
      :active-selection="false"
></oxygen-menu-table>
  <form-container
      :title="formContainerTitle"
      :form-rows="formRows"
      ref="formContainer"
  ></form-container>

</template>

  <script>
  import OxygenMenuTable from './../OxygenMenuTable/OxygenMenuTable.ce.vue';
  import InputString from './../InputString/InputString.ce.vue';
  import FormContainer from './../FormContainer/FormContainer.ce.vue';
  import * as oxygenUtil from './../OxygenUtil/oxygenutil.js';
  import Datepicker from '@vuepic/vue-datepicker';


  export default {
      components: { OxygenMenuTable, InputString, Datepicker, FormContainer },
      props: {
        searchCoordinator: Object,
        filterData: Object,
        viewport: Object,
        viewportId: String,
        filterSettingsCallback: Function,
        searchPrefix: String,
        selectionMode: {
          type: Boolean,
          default: false
        },
        selectionCallback: Function,
        searchCallback: Function,
        dataTable: Object
      },
      data: function () {
        return {
          rowStyle: {
            'line-height': '39px'
          },
          formContainerTitle: '',
          formRows: [],
          testDate: "",
          tableData: {
            searchLib: {},
            showCheckbox: false,
            showFolder: true,
            showHeader: false,
            showRightMenu: false,
            cols: [
              {
                header: {
                  type: 'html',
                  html: ''
                },
                type: 'html',
                colspan: 4,
                callback: () => {
                }
              },
              {
                header: {
                  type: 'html',
                  html: ''
                },
                type: 'html',
                colspan: 1,
                callback: () => {
                }
              },
              {
                header: {
                  type: 'html',
                  html: ''
                },
                type: 'html',
                width: '1.6em',
                colspan: 1,
                callback: () => {
                }
              },
              {
                header: {
                  type: 'html',
                  html: ''
                },
                type: 'html',
                colspan: 1,
                callback: () => {
                }
              },
              {
                header: {
                  html: ``
                },
                type: 'html',
                colspan: 1,
                callback: () => {
                }
              },
              {
                header: {
                  html: ``
                },
                type: 'html',
                colspan: 1,
                callback: () => {
                }
              },
              {
                header: {
                  html: ``
                },
                type: 'html',
                colspan: 1,
                callback: () => {
                }
              }],
            dataRows: []
          },
          rootValues: ["Folders", "Categories", "Ranges", "Sort By", "Advanced Options"],
          pathValues: {
            //search: 0,
            dir: '0',
            cat: '1',
            range: '2',
            sort: '3',
            opt: '4'
          },
          rangeTypes: {
            string: 'string',
            date: 'date',
            radio: 'radio'
          },
          dirMap: {},
          selectedDir: '',
          numOfFields: 0,
          numOfDirs: 0,
          numOfOpts: 0,
          numOfCats: 0,
          sortDirections: {
            asc: 'ASC',
            desc: 'DESC'
          },
          datePickerOptions: {
            enableTime: true,
            dateFormat: "Y-m-d H:i"
          },
          datePickerOptionsOld: {
            showClose: true,
            useCurrent: true,
            icons: {
              time: 'far fa-clock',
              date: 'far fa-calendar',
              up: 'fas fa-arrow-up',
              down: 'fas fa-arrow-down',
              previous: 'fas fa-chevron-left',
              next: 'fas fa-chevron-right',
              today: 'fas fa-calendar-check',
              clear: 'far fa-trash-alt',
              close: 'far fa-times-circle'
            }
          },
          folderHeaderCol: {
            html: '',
            enabled: false,
            callback: () => {
              if (this.folderHeaderCol.enabled) {
                this.showSaveSearchPanel();
              }
            }
          },
          filterTableShown: false,
          selectedCategories: [],
          advancedOptions: [
            /*{
              name: 'fetchAll',
              label: "Fetch All Results",
              type: "bool"
            },*/
            {
              name: 'searchResults',
              label: "No. of Search Results",
              type: "val"
            },
          ]
        }
      },

      mounted() {
        console.log('mounted', 'viewportId:', this.viewportId);
        this.viewportContext = oxygenUtil.getViewportContext(this.viewportId);
        this.hideDataRows();
        window.filterTable = this;
        this.$nextTick(function () {

          this.rootValues.forEach((val, index) => {
            this.$refs.menuTable.addRow({
              cols: [
                {
                  html: `<span class="pointer">${val}</span>`,
                  colspan: 8,
                  type: 'html',
                  styleObj: {'font-size': '12px'},
                  callback: (dataRow) => {
                    this.$refs.menuTable.folderClick(dataRow);
                  }
                }
              ],
              path: `${index}`,
              folderCol: {
                callback: (dataRow, index, expanded) => {
                  if (expanded) {
                    this.expandFolder(dataRow);
                  } else {
                    this.$refs.menuTable.collapseFolder(dataRow);
                  }
                }
              }
            });
          });
          this.setupFilterTableData();



          let intervalId = setInterval( ()=>{
            console.log('mounted-interval', 'ribbon:', this.searchCoordinator);
            if(this.searchCoordinator && this.searchCoordinator.refs.settingsRibbon)  {
              clearInterval(intervalId);
              this.addRibbonIcons();
            }
          }, 100);



          /*
            this.$refs.menuTable.setCheckboxHeaderCol({
                registrantId: '_FFFFFFFFFFFFFF00001577145419539002_',
                iconId: '_FFFFFFFFFFFFFF00001579303166983002_'
            }, this.filterSettingsClick.bind(this));*/
          this.getSavedSearches();
          if (this.selectionMode) {
            this.addSelectionButton();
          }
        });
      },
      computed: {},
      watch: {
        'selectedCategories': function () {
          this.constructTitle(this.selectedDir.dirName);
        },
        'selectionMode': function (newVal) {
          if (newVal) {
            this.addSelectionButton();
          } else {
            this.removeSelectionButton();
          }
        },
        'selectedDir': function (newVal) {
          this.constructTitle(this.selectedDir.dirName);

        }
      },
      methods: {
        async saveIconClicked() {
          this.formContainerTitle = "Save Search";
          this.formRows.length = 0;

          this.formRows.push(
              {
                type: "input",
                name: "saveSearchName",
                label: "Search Name",
                value: ''
              },
              {
                type: "button",
                css: "spaced-button",
                name: "saveSearch",
                text: "Save",
                size: "medium",
                view: "flat",
                color: "primary"
              }
          );
          this.$refs.formContainer.showContainerAlignedToElementInViewport(
              document.getElementById(this.viewport.viewportID).querySelector(".main-panel-header-title"),
              'bottom',
              document.getElementById(this.viewport.viewportID).getBoundingClientRect()
          );

          await this.$nextTick();
          this.$refs.formContainer.formInstance.getItem("saveSearch").events.on("click", async ()=>{
            let searchName = this.$refs.formContainer.formInstance.getItem("saveSearchName").getValue();
            await this.saveSearch(searchName);
            this.$refs.formContainer.hide();
          });

        },
        addRibbonIcons() {
          this.searchCoordinator.refs.settingsRibbon.addLeftRibbonIcon(
              "save_search",  { type: "css", classes: "fal fa-save"}, this.saveIconClicked.bind(this), 'Save', false
          );
        },
        isShown() {
          return this.$refs.menuTable.showDataRows;
        },
        selectedRowsChanged(selectedRows) {
          //this.parent.debugLog('selectedRowsChanged', 'selectedRow:', selectedRows);
          this.selectedCategories = selectedRows.filter(e => (e.path.startsWith(this.pathValues.cat) && (e.dirName === this.selectedDir.name)));
          //this.parent.debugLog('selectedRowsChanged', 'selectedCategories:', this.selectedCategories);
        },
        dataRowSelected(dataRow, index) {
          if (this.isDirectory(dataRow)) {
            //this.parent.debugLog('dataRowDeselected', 'dataRow.path:', dataRow.path);
            //this.parent.debugLog('dataRowDeselected', 'this.selectedDir.path:', this.selectedDir.path || '');
            if (this.selectedDir && this.selectedDir.path && this.selectedDir.path !== dataRow.path) {
              let prevSelectedDir = this.selectedDir;
              this.setSelectedDirectory(dataRow);
              this.resetDirectoryRows();
              this.$refs.menuTable.deselectRow(prevSelectedDir.path);
            } else {
              this.setSelectedDirectory(dataRow);
              this.resetDirectoryRows();
            }
          }
        },
        selectDirectory(dirName) {
          let dir;
          if (dir = this.getDirectory(dirName)) {
            this.$refs.menuTable.selectRow(dir.path);
            return true;
          }
          return false;
        },
        resetDirectoryRows() {
          let paths = [this.pathValues.cat, this.pathValues.range, this.pathValues.sort];
          paths.forEach((path) => {
            let folderRow;
            //this.parent.debugLog('resetDirectoryRows', 'path:', path, ' - expanded:', this.$refs.menuTable.getRow(path).expanded);
            //console.log('resetDirectoryRows', 'path:', path, ' - row:', this.$refs.menuTable.getRow(path));
            if (folderRow = !this.$refs.menuTable.getRow(path) ? null : this.$refs.menuTable.getRow(path).expanded) {
              this.$refs.menuTable.getPathRows(`${path}/`).forEach((row) => {
                if (row.dirName === this.selectedDir.name) {
                  row.show = true;
                } else {
                  row.show = false;
                }
              });
              //this.$refs.menuTable.folderClick(row, this.$refs.menuTable.getRowIndex(row.path));
              //this.$refs.menuTable.folderClick(row, this.$refs.menuTable.getRowIndex(row.path));
              //this.$refs.menuTable.collapseFolder(row);
              //this.expandFolder(row);
            }
          });
        },
        dataRowDeselected(dataRow, index) {
          //this.parent.debugLog('dataRowDeselected', 'dataRow.path:', dataRow.path);
          //this.parent.debugLog('dataRowDeselected', 'this.selectedDir.path:', this.selectedDir.path);
          if (this.isDirectory(dataRow) && dataRow.path === this.selectedDir.path) {
            this.$refs.menuTable.selectRow(dataRow.path);
          }
        },
        isDirectory(dataRow) {
          return dataRow.path.startsWith(`${this.pathValues.dir}/`);
        },
        removeSelectionButton() {
          this.removeHeaderButton();
        },

        addSelectionButton() {
          this.tableData.showHeader = true;
          this.tableData.cols[1]['colspan'] = 4;
          this.tableData.cols[1]['styleObj'] = {'padding-right': '0'};
          this.tableData.cols[1]['header'] = {
            type: 'html',
            html: `<span style="display: flex; flex-direction: row-reverse;"><button type="button" class="dhx_button dhx_button--color_primary dhx_button--size_medium dhx_button--view_flat"><span class="dhx_button__text">Select</span></button></span>`
          };
          this.tableData.cols[1]['callback'] = this.selectionCallback.bind(this);
        },
        addHeaderButton(label, callback) {
          this.tableData.showHeader = true;
          this.tableData.cols[1]['colspan'] = 4;
          this.tableData.cols[1]['styleObj'] = {'padding-right': '0'};
          this.tableData.cols[1]['header'] = {
            type: 'html',
            html: `<span style="display: flex; flex-direction: row-reverse;"><button type="button" class="dhx_button dhx_button--color_primary dhx_button--size_medium dhx_button--view_flat"><span class="dhx_button__text">${label}</span></button></span>`
          };
          this.tableData.cols[1]['callback'] = callback;
        },
        removeHeaderButton() {
          this.tableData.showHeader = false;
          this.tableData.cols[1]['colspan'] = 1;
          this.tableData.cols[1]['header'] = {
            type: 'html',
            html:``
          };
          this.tableData.cols[1]['callback'] = () => {
          };
        },
        expandFolder(dataRow) {
          //this.parent.debugLog('expandFolder', 'dataRow.path:', dataRow.path);
          //this.parent.debugLog('expandFolder', 'this.pathValues.dir:', this.pathValues.dir);
          this.$refs.menuTable.pathMap[dataRow.path].children.forEach((row) => {
            //this.parent.debugLog('expandFolder', 'row:', row);
            if ((dataRow.path == this.pathValues.opt) || (dataRow.path == this.pathValues.dir) || (dataRow.path == this.pathValues.search) || (row.dirName === this.selectedDir.name)) {
              row.show = true;
            }
          });

        },
        getSavedSearches() {
          /*
          let mainComponent = this.parent.parent || this.parent;
          mainComponent.getFilterSearchData(this.searchPrefix, (error, data) => {
            if (!error) {
              //this.parent.debugLog('getSavedSearches', 'data:', data);
              this.$refs.menuTable.deletePathRows(`${this.pathValues.search}/`);
              data.forEach(this.addSavedSearch.bind(this));
            }
          });

           */
        },

        updateExistingSearch(savedSearch) {
          this.saveSearch(savedSearch.name, savedSearch.saveId, () => {

          });
        },
        async saveSearch(searchName, ...args) {
          let callback = args[args.length - 1], saveId;
          if (args.length > 1) {
            saveId = args[0];
          }

          return await oxygenUtil.saveFilterSearchData(this.viewportContext.contextPE, this.searchPrefix, searchName, {
            dir: this.selectedDir.path,
            cats: this.$refs.menuTable.getPathRows(this.pathValues.cat + '/').map((e) => {
              return {
                path: e.path,
                selected: e.selected
              };

            }),
            ranges: this.$refs.menuTable.getPathRows(this.pathValues.range + '/').map((e) => {
              let range = {
                path: e.path,
                selected: e.selected,
                type: e.rangeType
              };
              if(e.rangeType === this.rangeTypes.radio) {
                range.value = e.value;
              }
              else {
                if (e.cols[1].value) {
                  range.value1 = e.cols[1].value;
                }
                if (e.cols[2].value) {
                  range.value2 = e.cols[2].value;
                }
              }

              return range;
            }),
            sorts: this.$refs.menuTable.getPathRows(this.pathValues.sort + '/').map((e) => {
              return {
                path: e.path,
                name: e.name,
                sortDirection: e.sortDirection,
                selected: e.selected
              }
            })
          }, saveId);
        },
        setFolderHeaderCol: function (folderCol) {
          this.$refs.menuTable.setFolderHeaderCol(folderCol)
        },
        filterSettingsClick: function () {
          this.toggleDataRows();
          if (this.filterSettingsCallback) {
            this.filterSettingsCallback(this.$refs.menuTable.showDataRows);
          }
        },
        toggleDataRows: function () {
          if (!this.$refs.menuTable.showDataRows) {
            this.displayDataRows();
          } else {
            this.hideDataRows();
          }
        },
        displayDataRows() {
          this.filterTableShown = true;
          //this.folderHeaderCol.iconId = '_FFFFFFFFFFFFFF00001580586372026002_';
          //this.folderHeaderCol.enabled = true;
          if(this.searchCoordinator && this.searchCoordinator.refs.settingsRibbon ) {
            this.searchCoordinator.refs.settingsRibbon.showRibbonIcon('save_search');
          }

          this.$refs.menuTable.displayDataRows();
        },
        hideDataRows() {
          this.filterTableShown = false;
          //this.folderHeaderCol.iconId = '_FFFFFFFFFFFFFF00001579202909427002_';
          //this.folderHeaderCol.enabled = false;

          if(this.searchCoordinator && this.searchCoordinator.refs.settingsRibbon ) {
            this.searchCoordinator.refs.settingsRibbon.hideRibbonIcon('save_search');
          }


          this.$refs.menuTable.hideDataRows();
        },
        setSelectedDirectory: function (dir) {
          this.selectedDir = dir;
          this.constructTitle(dir.name);
          //this.parent.debugLog('setSelectedDirectory', this.selectedDir);
          this.$emit('selected-dir-changed', this.selectedDir);

        },
        constructTitle(dirName) {
          let catString = this.selectedCategories.map(e => e.name).join(',');
          //this.parent.debugLog('constructTitle', 'catString:', catString);
          //this.tableData.cols[0].header.html = `<span>${dirName}${catString ? '&nbsp;/&nbsp;' + catString : ''}</span>`;
          if (this.dataTable.tableData.cols && this.dataTable.tableData.cols[1]) {
            this.dataTable.tableData.cols[1]['header']= {
              type: 'html',
              html: `<span style="font-size:12px;">${dirName}${catString ? '&nbsp;/&nbsp;' + catString : ''}</span>`
            };
          }
        },
        getCategory(dirName, catName) {
          return this.$refs.menuTable.getPathRows(this.pathValues.cat).filter(e => (e.dirName === dirName && e.name === catName))[0] || null;
        },
        getSelectedCategories: function () {
          //this.parent.debugLog('getSelectedCategories', 'selected cats:', this.$refs.menuTable.getSelectedPathRows(this.pathValues.cat).filter(e => e.dirName === this.selectedDir.name));
          //this.parent.debugLog('getSelectedCategories', 'menuTable:', this.$refs.menuTable);
          return this.$refs.menuTable.getSelectedPathRows(this.pathValues.cat).filter(e => e.dirName === this.selectedDir.name);
        },
        getSelectedRangeValues: function () {
          return this.$refs.menuTable.getSelectedPathRows(this.pathValues.range).filter(e => e.dirName === this.selectedDir.name);
        },
        getSelectedSortByValues: function () {
          return this.$refs.menuTable.getSelectedPathRows(this.pathValues.sort).filter(e => e.dirName === this.selectedDir.name);
        },
        unselectAllCategories() {
          this.$refs.menuTable.getPathRows(`${this.pathValues.cat}/`).filter(e => e.dirName === this.selectedDir.name).forEach((row) => {
            this.$refs.menuTable.deselectRow(row.path);
          });
        },
        selectCategory(dirName, catName) {
          this.$refs.menuTable.selectRow(this.getCategory(dirName, catName).path);
        },
        resetRangeValues() {
          this.$refs.menuTable.getPathRows(this.pathValues.range + '/').forEach((row) => {
            this.$refs.menuTable.deselectRow(row.path);
            row.cols[1].value = '';
            row.cols[2].value = '';
          });
        },
        resetSortByValues() {
          this.$refs.menuTable.getPathRows(this.pathValues.sort + '/').forEach((row) => {
            this.$refs.menuTable.deselectRow(row.path);
          });
        },
        async deleteSavedSearch(saveId) {
          await oxygenUtil.deleteSavedSearch(this.viewportContext.contextPE, saveId);

        },
        setSavedSearch(data) {
          //this.setSelectedDirectory(this.$refs.menuTable.getRow(data.dir));
          this.$refs.menuTable.selectRow(data.dir);
          this.selectDirectory(this.$refs.menuTable.getRow(data.dir).name);

          data.cats.forEach((cat) => {
            if (cat.selected) {
              this.$refs.menuTable.selectRow(cat.path);
            } else {
              this.$refs.menuTable.deselectRow(cat.path);
            }
          });

          data.ranges.forEach((range) => {
            if (range.selected) {
              let row = this.$refs.menuTable.selectRow(range.path);
              if(range.type === this.rangeTypes.radio) {
                console.log('setSavedSearch', 'el:', this.$el.parentElement, ' - fieldName:', row.fieldName, ' - val:', range.value);
                console.log('setSavedSearch', 'selectorStr:', `input[name="${row.fieldName}"][value="${range.value}"]`);
                this.$el.parentElement.querySelector(`input[name="${row.fieldName}"][value="${range.value}"]`).checked = true;
                row.value = range.value;
              }
              else {
                row.cols[1].value = range.value1;
                row.cols[2].value = range.value2;
              }
            } else {
              this.$refs.menuTable.deselectRow(range.path);
            }
          });

          data.sorts.forEach((sort, index) => {
            let row;

            if (row = this.$refs.menuTable.getPathRowByAttrValue(this.pathValues.sort + '/', 'name', sort.name)) {
              row.path = sort.path;
              if (sort.selected) {
                this.$refs.menuTable.selectRow(row.path);
                row.cols[1].html = this.getSortIcon(sort.sortDirection);
              } else {
                this.$refs.menuTable.deselectRow(row.path);
              }
            }
          });

          this.searchCallback('',()=>{});
        },
        addSavedSearch(searchData, index) {
          let numStr = `${index}`;
          numStr = numStr.padStart(10, '0');
          let path = `${this.pathValues.search}/${numStr}`;
          let searchProps = {
            cols: [
              {
                html: `<span style="cursor:pointer;">${searchData.name}</span>`,
                styleObj: {'font-size': '12px'},
                colspan: 8,
                callback: (searchRow) => {
                  this.setSavedSearch(searchRow.data);
                }
              }
            ],
            path,
            name: searchData.name,
            data: searchData.data,
            show: true
          };
          this.$refs.menuTable.addRow(searchProps);
          //this.parent.debugLog('addSavedSearch', 'dataRows:', this.tableData.dataRows);
        },
        getDirectory(dirName) {
          return this.$refs.menuTable.getPathRows(this.pathValues.dir).find(e => e.dirName === dirName);
        },
        setupFilterTableData() {
          if(this.filterData && Array.isArray(this.filterData.directories) ) {
            this.filterData.directories.forEach((dir)=>{
              this.addDirectory(dir);
              dir.categories.forEach((cat)=>{
                this.addCategory(cat, dir.name);
              });
              dir.fields.forEach((field)=>{
                this.addField(field, dir.name);
              })
            });
          }
          this.advancedOptions.forEach((e) =>  {
            if(e.type === "bool") {
              this.addBoolAdvancedOption(e);
            }
            else {
              this.addValueAdvancedOption(e);
            }
          });

        },
        addBoolAdvancedOption(opt) {
          this.numOfOpts++;
          let path = `${this.pathValues.opt}/${`${this.numOfOpts}`.padStart(10, '0')}`;
          let optProps = {
            cols: [
              {
                html: `<span>${opt.label}</span>`,
                type: 'html',
                colspan: 8,
                styleObj: {'font-size': '12px'},
                callback: () => {
                  //this.setSelectedDirectory(dir);
                }
              }
            ],

            path,
            name: opt.name,
            show: true,
            showCheckbox: true
          };
          Object.assign(opt, optProps);
          this.$refs.menuTable.addRow(opt);

          if (opt.selected) {
            this.selectOption(opt.name);
          }
        },
        addValueAdvancedOption(opt) {
          this.numOfOpts++;
          let path = `${this.pathValues.opt}/${`${this.numOfOpts}`.padStart(10, '0')}`;
          let optProps = {
            cols: [
              {
                html: `<span>${opt.label}</span>`,
                type: 'html',
                colspan: 4,
                styleObj: {'font-size': '12px'},
                callback: () => {
                  //this.setSelectedDirectory(dir);
                }
              },
              {
                type: 'component',

                componentName: InputString,
                colspan: 3,
                value: '',
                props: {
                  placeholder: '',
                  style: "width: 100%;",
                  addValuesToMessageBox: false,
                  messageBoxComponent: this.viewport.MessageBox
                },
                styleObj: {overflow: 'visible', 'font-size': '12px'},
                callback: () => {
                }
              }
            ],

            path,
            name: opt.name,
            show: true,
            showCheckbox: true
          };
          Object.assign(opt, optProps);
          this.$refs.menuTable.addRow(opt);

          if (opt.selected) {
            this.selectOption(opt.name);
          }
        },
        selectOption(optName) {
          let opt;
          if (opt = this.getOption(optName)) {
            this.$refs.menuTable.selectRow(opt.path);

            return true;
          }
          return false;
        },
        getOption(optName) {
          return this.$refs.menuTable.getPathRows(this.pathValues.opt).find(e => e.name === optName);
        },
        getAdvancedOptions() {
          return this.advancedOptions.reduce((acc, cur)=>{
            let optRow = this.getOption(cur.name);
            if(cur.type === "bool") {
              acc[cur.name] = optRow.selected;
            }
            else if(cur.type === "val" && optRow.selected) {
              acc[cur.name] = optRow.cols[1].value;
            }
            return acc;
          },{});
        },
        addDirectory: function (dir) {
          this.numOfDirs++;
          let numStr = `${this.numOfDirs}`;
          numStr = numStr.padStart(10, '0');
          let path = `${this.pathValues.dir}/${numStr}`;
          let dirProps = {
            cols: [
              {
                html: `<span>${dir.name}</span>`,
                type: 'html',
                colspan: 8,
                styleObj: {'font-size': '12px'},
                callback: (dataRow) => {
                  //this.setSelectedDirectory(dir);
                  this.$refs.menuTable.selectRow(dir.path);
                }
              }
            ],
            tableName: dir.tableName,
            path,
            name: dir.name,
            dirName: dir.name,
            show: true,
            showCheckbox: true
          };
          Object.assign(dir, dirProps);
          this.$refs.menuTable.addRow(dir);
          this.dirMap[dir.name] = path;
          if (dir.selected) {
            this.$refs.menuTable.selectRow(dir.path);
            //this.setSelectedDirectory(dir);
            //this.selectDirectory(dir.name);
          }
        },
        addCategory: function (cat, dirName) {
          this.numOfCats++;
          let numStr = `${this.numOfCats}`;
          numStr = numStr.padStart(10, '0');
          let path = `${this.pathValues.cat}/${numStr}`;
          let catProps = {
            cols: [
              {
                html: `<span>${cat.name}</span>`,
                colspan: 8,
                type: 'html',
                styleObj: {'font-size': '12px'},
                callback: (dataRow) => {
                  if(dataRow.selected) {
                    this.$refs.menuTable.deselectRow(dataRow.path);
                  }
                  else {
                    this.$refs.menuTable.selectRow(dataRow.path);
                  }
                  if(dataRow.catGroup) {
                    this.getSelectedCategories().filter(e => e.catGroup !== dataRow.catGroup).forEach((e)=>{
                      this.$refs.menuTable.deselectRow(e.path);
                    });
                  }
                }
              }

            ],
            path,
            dirName,
            name: cat.name,
            show: this.selectedDir ? (this.selectedDir.name === dirName ? true : false) : false,
            showCheckbox: true
          };
          Object.assign(cat, catProps);
          this.$refs.menuTable.addRow(cat);
          if (cat.selected) {
            this.$refs.menuTable.selectRow(path);
          }
        },
        getSortIcon: function (sortDirection) {
          let iconId = {
            [this.sortDirections.asc]: () => `<span class="fal fa-sort-alpha-down"/>`,
            [this.sortDirections.desc]: () => `<span class="fal fa-sort-alpha-down-alt"/>`
          };
          return iconId[sortDirection]();
        },
        getRangeValCols: function (field, rangeType, path) {
          let rangeTypeFn = {
            [this.rangeTypes.string]: () => {
              field['prop1'] = {
                eventHandlers: {
                  input: (...args) => {
                    let row = this.$refs.menuTable.getRow(path);
                    if(row && !row.selected) {
                      this.$refs.menuTable.selectRow(path);
                    }
                  }
                },
                placeholder: 'min',
                style: "width: 100%;",
                addValuesToMessageBox: false,
                messageBoxComponent: this.viewport.MessageBox
              };
              field['prop2'] = {
                eventHandlers: {
                  input: (...args) => {
                    let row = this.$refs.menuTable.getRow(path);
                    if(row && !row.selected) {
                      this.$refs.menuTable.selectRow(path);
                    }
                  }
                },
                placeholder: 'max',
                style: "width: 100%;",
                addValuesToMessageBox: false,
                messageBoxComponent: this.viewport.MessageBox
              };
              return [
                {
                  type: 'component',
                  componentName: InputString,
                  colspan: 3,
                  value: '',
                  props: field.prop1,
                  styleObj: {overflow: 'visible'},
                  callback: () => {
                  }
                },
                {
                  type: 'component',
                  colspan: 3,
                  value: '',
                  componentName: InputString,
                  styleObj: {overflow: 'visible'},
                  props: field.prop2
                }
              ];
            },

            [this.rangeTypes.date]: () => {
              field['prop1'] = {
                utc: true
                //config: this.datePickerOptions,
                //style: "width: 100%;"
                //,
                //placeholder: 'Begin'

              };
              field['prop2'] = {
                utc: true
                //config: this.datePickerOptions,
                //style: "width: 100%;"
                //,
                //placeholder: 'End'
              };
              return [
                {
                  type: 'component',
                  componentName: Datepicker,
                  value: '',
                  colspan: 3,
                  props: field.prop1,
                  eventHandlers: {
                    'update:modelValue': (modelValue) => {
                      let row = this.$refs.menuTable.getRow(path);
                      if(row && !row.selected && modelValue) {
                        this.$refs.menuTable.selectRow(path);
                      }
                    }
                  },
                  callback: () => {
                  }
                },
                {
                  type: 'component',
                  componentName: Datepicker,
                  value: '',
                  colspan: 3,
                  props: field.prop2,
                  eventHandlers: {
                    'update:modelValue': (modelValue) => {
                      let row = this.$refs.menuTable.getRow(path);
                      if(row && !row.selected && modelValue) {
                        this.$refs.menuTable.selectRow(path);
                      }
                    }

                  },
                  callback: () => {
                  }
                },

              ];
            },
            [this.rangeTypes.radio]: () => {
              field['prop1'] = {
                placeholder: 'min',
                style: "width: 100%;",
                addValuesToMessageBox: false,
                messageBoxComponent: this.viewport.MessageBox

              };
              field['prop2'] = {
                placeholder: 'max',
                style: "width: 100%;",
                addValuesToMessageBox: false,
                messageBoxComponent: this.viewport.MessageBox

              };
              field.id1 = `id${oxygenUtil.getUUID()}`;
              field.id2 = `id${oxygenUtil.getUUID()}`;
              field.fieldName = `name${oxygenUtil.getUUID()}`;
              return [
                {
                  type: 'html',
                  html: `<div>
      <input type="radio" id="${field.id1}" name="${field.fieldName}" value="${field.options[0].value}">
      <label for="${field.id1}">${field.options[0].name}</label>
    </div>`,
                  colspan: 3,
                  value: '',
                  props: field.prop1,
                  styleObj: {overflow: 'visible'},
                  callback: (dataRow) => {
                    let row = this.$refs.menuTable.getRow(path);
                    if(row && !row.selected) {
                      this.$refs.menuTable.selectRow(path);
                    }
                    dataRow.value = field.options[0].value;
                  }
                },
                {
                  type: 'html',
                  html: `<div>
      <input type="radio" id="${field.id2}" name="${field.fieldName}" value="${field.options[1].value}">
      <label for="${field.id2}">${field.options[1].name}</label>
    </div>`,
                  colspan: 3,
                  value: '',
                  props: field.prop2,
                  styleObj: {overflow: 'visible'},
                  callback: (dataRow) => {
                    let row = this.$refs.menuTable.getRow(path);
                    if(row && !row.selected) {
                      this.$refs.menuTable.selectRow(path);
                    }

                    dataRow.value = field.options[1].value;
                  }
                },
              ];
            }
          };
          return rangeTypeFn[rangeType || this.rangeTypes.string]();
        },
        addField: function (field, dirName) {
          this.numOfFields++;
          let numStr = `${this.numOfFields}`;
          //this.parent.debugLog('addField', 'numStr', numStr);
          numStr = numStr.padStart(10, '0');
          //this.parent.debugLog('addField', 'numStr-after padding', numStr);
          let path = `${this.pathValues.range}/${numStr}`;

          let rangeValCols = this.getRangeValCols(field, field.rangeType, path);
          let rangeProps = {
            cols: [
              {
                html: `<span>${field.label}</span>`,
                colspan: 2,
                styleObj: {'font-size': '12px'},
                callback: () => {
                }
              },
              ...rangeValCols,

              /*{
                        registrantId: '_FFFFFFFFFFFFFF00001577145419539002_',
                        iconId: '_FFFFFFFFFFFFFF00001579202909427002_',
                        callback: function () {
                        }
                    },
                    {
                        registrantId: '_FFFFFFFFFFFFFF00001577145419539002_',
                        iconId: '_FFFFFFFFFFFFFF00001579202909427002_',
                        callback: function () {
                        }
                    },
                    {
                        registrantId: '_FFFFFFFFFFFFFF00001577145419539002_',
                        iconId: '_FFFFFFFFFFFFFF00001579202909427002_',
                        callback: function () {
                        }
                    }*/

            ],
            path,
            name: field.name,
            dirName,
            show: this.selectedDir ? (this.selectedDir.name === dirName ? true : false) : false,
            showCheckbox: true
          };
          //this.parent.debugLog('addField', 'rangeProps:', rangeProps);
          this.$refs.menuTable.addRow(Object.assign({}, field, rangeProps));

          let sortProps = {
            cols: [
              {
                html: `<span>${field.label}</span>`,
                styleObj: {'font-size': '12px'},
                colspan: 5,
                callback: () => {
                }
              },
              {
                type: 'html',
                html: ``,
                styleObj: {'font-size': '12px'},
                callback: (dataRow) => {
                  if (dataRow.selected) {
                    dataRow.sortDirection = (dataRow.sortDirection === this.sortDirections.asc) ? this.sortDirections.desc : this.sortDirections.asc;
                    dataRow.cols[1].html = this.getSortIcon(dataRow.sortDirection);
                  }
                }
              },
              {
                type: 'html',
                html: ``,
                styleObj: {'font-size': '12px'},
                callback: (dataRow, index) => {
                  if (dataRow.selected && dataRow.path !== `${this.pathValues.sort}/${'1'.padStart(10,'0')}` ) {
                    let oldPath = dataRow.path;
                    dataRow.path = this.$refs.menuTable.tableData.dataRows[index - 1].path;
                    this.$refs.menuTable.tableData.dataRows[index - 1].path = oldPath;
                    this.$refs.menuTable.setFolderView();
                  }
                }
              },
              {
                type: 'html',
                html: ``,
                styleObj: {'font-size': '12px'},
                callback: (dataRow, index) => {
                  if (dataRow.selected) {
                    let lastPath = `${this.numOfFields}`;
                    lastPath = `${this.pathValues.sort}/${lastPath.padStart(10, '0')}`;
                    if (dataRow.path !== lastPath) {
                      let oldPath = dataRow.path;
                      dataRow.path = this.$refs.menuTable.tableData.dataRows[index + 1].path;
                      this.$refs.menuTable.tableData.dataRows[index + 1].path = oldPath;
                      this.$refs.menuTable.setFolderView();
                    }
                  }
                }
              }
            ],
            name: field.name,
            path: `${this.pathValues.sort}/${numStr}`,
            dirName,
            show: this.selectedDir ? (this.selectedDir.name === dirName ? true : false) : false,
            showCheckbox: true,
            sortDirection: this.sortDirections.asc,
            checkboxCol: {
              callback: (dataRow) => {
                if (dataRow.selected) {
                  dataRow.cols[1].html = this.getSortIcon(dataRow.sortDirection);
                  dataRow.cols[2].html = `<span class="far fa-angle-up"/>`;
                  dataRow.cols[3].html = `<span class="far fa-angle-down"/>`;
                } else {
                  dataRow.cols[1].html = '';
                  dataRow.cols[2].html = '';
                  dataRow.cols[3].html = '';
                }
              }
            }
          };

          this.$refs.menuTable.addRow(Object.assign({}, field, sortProps));
        }
      }
    }
  </script>
<style>
/*@import '@vuepic/vue-datepicker/dist/main.css';*/

@import url('/simplia/dist/css/vue-datepicker.css');

</style>
import validator from 'validator';
import {callCommonServiceAPI} from './../ServerCaller/server-caller.js';
import { parsePhoneNumberFromString, isValidPhoneNumber } from 'libphonenumber-js';
import * as historyLib from './../HistoryLib/historylib.js';

let validateMap = {
    'Oxygen OID': isGUID,
    'String': validator.isAscii,
    'Number': validator.isNumeric,
    'Date': validator.isDate,
    'Phone Number': (num )=> { return isValidPhoneNumber(num, 'US'); },
    'Email Address': validator.isEmail,
    'Credit Card': validator.isCreditCard,
    'IP Address': validator.isIP,
    'Currency': validator.isCurrency,
    'Domain Name': validator.isFQDN,
    'Strong Password': validator.isStrongPassword,
    'Date / Time': validator.isISO8601,
    'Time': validator.isTime,
    'URL': validator.isURL,
    'User Email': isUserEmail,
    'Component': isComponent,
    'Signature': isSignature
};

let transformMethodMap = {
    'Phone Number': (phoneNumber)=> {
        return parsePhoneNumberFromString(phoneNumber, 'US').format('E.164')
    }
};

function isAsync(fn) {
    return fn.constructor.name === 'AsyncFunction';
}

async function validate(type, string) {
    //console.log('validate', 'validateMap[type]:', validateMap[type], ' - val:', validateMap[type] && validateMap[type](string));

    let val = validateMap[type] && (isAsync(validateMap[type]) ? await validateMap[type](string) : validateMap[type](string));
    //console.log('validate', 'transformMethodMap[type]:', transformMethodMap[type], ' - transform-val:',  transformMethodMap[type] && transformMethodMap[type](val));
    return val && ( (transformMethodMap[type] && transformMethodMap[type](string)) || val);
}

async function isUserEmail(email) {
    try {
        let results = await runDBQuery(
            `select * from OxygenDatabase.Attribution where GatewayRightDN like ? escape ? and AccountType = ?`,
            [`${email.replace(/_/g,'\\_')}%`, `\\`, 'PrimaryAccount']
        );
        if(results.length) {
            return true;
        }
        else {
            return false;
        }
    }
    catch(e) {
        return false;
    }
}

async function getOxygenPTypes(category) {
    try {
        let results = await callCommonServiceAPI("simplia-api", "doDynDBQuery", Object.assign({
            TableName: 'GlobalSchemas',
            IndexName: 'MetaType-index',
            KeyConditionExpression: 'MetaType = :metaType',
            ExpressionAttributeValues: Object.assign({ ':metaType': 'OxygenPType' }, category ? { ':category': category}: {})
        }, category ? { FilterExpression: 'Category = :category'} : {}));
        return results.Items;
    }
    catch(e) {
        return false;
    }

}

async function isComponent(componentDN) {
    let component = await callCommonServiceAPI("simplia-api", "getComponentByDisplayName",{componentDN});
    return !!component;
}

async function isSignature(data) {
    return true;
}


async function runDBQuery(query, data, clientId, grantId) {
    let registerVal;
    clientId = clientId || ( (window.botStore && window.botStore.clients && window.botStore.clients[0]) ? window.botStore.clients[0].Client :
        '_FF07609117308816579277396602531608_');
    grantId = grantId || (window.LivingScript_0070 ? window.LivingScript_0070.context.grantId : '_FFFFFFFFFFFFFF16563664199331282052_');

    if(registerVal = await historyLib.register(
        {
            ClientId: clientId,
            GrantID: grantId
        })) {
        let {results} = await historyLib.action({

            ClientId: clientId,
            GrantID: grantId,
            Path: 'SQL/Query',
            query,
            data
        });

        return results;
    }
}



function isGUID(str) {
    let regEx1 = /^_[A-Z0-9]{34}_$/;
    let regEx2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z[A-Z\-]{9}$/;
    return regEx1.test(str) || regEx2.test(str);
}

export {
    getOxygenPTypes,
    validate
}
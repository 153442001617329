var defaultSegmentEncoding = "1";
var defaultEdgeEncoding = "1";
var segmentSeparator = String.fromCharCode(127);
var appId = "2016-08-26T19:27:07.576010ZFFF-FFFFZ";
var defaultIconColor = 'black';
var pdfOutputDivId = `pdfOutput`;
var pdfContentImgId = 'pdfContentImg';
var config = {
    attachmentsTitle: 'Related Links',
    attachmentTitle: 'Related Link',
    defaultIconColor: 'black',
    fontSize: '12px',
    headerFontSize: '12px',
    headerFontWeight: '500'
};


import * as historyLib from './../HistoryLib/historylib.js';
import * as async from 'async-es';
import ical, {ICalAttendee} from 'ical-generator';
import * as Cookies from 'es-cookie';
import { parsePhoneNumberFromString, isValidNumber } from 'libphonenumber-js';
import Mustache from 'mustache';
import * as validator from './../OxygenValidator/oxygen-validator.js';
import {callCommonServiceAPI} from './../ServerCaller/server-caller.js';
import { marked } from 'marked';



function formatDN(DN, anonFlag) {
    let matches;
    if(isGUID(DN)) {
        return `...${DN.substring(31,35)}`;
    }
    else if(matches = /^(_[A-Z0-9]{34}_)(\/.+)$/.exec(DN)) {
        return `...${matches[1].substring(31,35)}${matches[2]}`;
    }
    if(anonFlag && (matches = /^Anonymous\s+Account(.+)$/.exec(DN))) {
        return `Anon-${matches[1].substring(matches[1].length - 4)}`;
    }
    let regEx = /^([^-]+)(-[-\d]+|\/Personal\s+Account*)*$/;
    let results = regEx.exec(DN);
    return Array.isArray(results) ? results[1] : DN;
}

function isGUID(str) {
    let regEx1 = /^_[A-Z0-9]{34}_$/;
    let regEx2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z[A-Z\-]{9}$/;
    return regEx1.test(str) || regEx2.test(str);
}

function formatFullDateString(dateObj){
    return `${formatExtendedDateOnly(dateObj)} ${formatTimeOnly(dateObj)}`;
}

function formatDateOnly(dateObj){
    let date = `${dateObj.getDate()}`;
    date = date.padStart(2, '0');
    let month = `${dateObj.getMonth() + 1}`;
    month = month.padStart(2, '0');

    return `${month}/${date}`;
}

function formatExtendedDateOnly(dateObj){
    let date = `${dateObj.getDate()}`;
    date = date.padStart(2, '0');
    let month = `${dateObj.getMonth() + 1}`;
    month = month.padStart(2, '0');

    return `${month}/${date}/${dateObj.getFullYear()}`;
}

function formatTimeOnly(dateObj){
    let hours = dateObj.getHours(), minutes = dateObj.getMinutes(), colon = ':';

    if(hours === 0) {
        hours = 12;
    }
    else if(hours < 10 ) {
        hours = `0${hours}`;
    }
    else if(hours >= 12) {
        colon = `<span style="font-weight:bold">:</span>`;
        if(hours > 12) {
            hours = `0${hours - 12}`;
        }
    }

    if(minutes < 10) {
        minutes = "0" + minutes;
    }
    return `${hours}${colon}${minutes}`;
}

function getFormattedTime(dateStr, compareDate, timeOnly, fullDateCondition){
    let dateObj = new Date(dateStr);
    if(timeOnly) {
        return formatTimeOnly(dateObj);
    }
    if(fullDateCondition) {
        return formatFullDateString(dateObj);
    }
    else {
        //this.parent.debugLog('getFormattedTime', 'compareDate:', compareDate, ' - dateObj:', dateObj);
        if((compareDate.getFullYear() === dateObj.getFullYear()) &&
            (compareDate.getMonth() === dateObj.getMonth()) &&
            (compareDate.getDate() === dateObj.getDate()) ) {
            return formatTimeOnly(dateObj);
        }
        else {
            return formatDateOnly(dateObj);
        }
    }
}

function createOES(edgeEncoding, segments, endString) {
    let str = edgeEncoding;
    for (let i in segments) {
        str += segments[i].encoding + segments[i].segment + segmentSeparator;
    }
    return str + segmentSeparator + (endString || "");
}



function createSimpleOES(segmentStrings, endString) {
    let segments = [];
    for (let i in segmentStrings) {
        segments[i] = {encoding: defaultSegmentEncoding, segment: segmentStrings[i]};
    }
    return createOES(defaultEdgeEncoding, segments, endString || undefined);
}

function createSimplePartialOES(segmentStrings) {
    let segments = [];
    for (let i in segmentStrings) {
        segments[i] = {encoding: defaultSegmentEncoding, segment: segmentStrings[i]};
    }
    return createPartialOES(defaultEdgeEncoding, segments);
}

function createPartialOES(edgeEncoding, segments) {
    let str = edgeEncoding;
    for (let i in segments) {
        str += segments[i].encoding + segments[i].segment + segmentSeparator;
    }
    return str.substr(0);
}

function createPartialOESSegments(segments) {
    let str = '';
    for (let i in segments) {
        str += segments[i].encoding + segments[i].segment + segmentSeparator;
    }
    return str.substr(0);
}


function parseOES(oes) {
    let encoding = oes.charAt(0);
    let segmentsStr = oes.slice(1);
    let exp = /.([^\x7F]+)\x7F/g;

    let match = exp.exec(segmentsStr);
    let matches = {
        segments: []
    };
    while (match !== null) {
        //lib.libDebugLog('parseOES','match:', match);
        matches.segments.push(match[1]);
        match = exp.exec(segmentsStr);
    }
    let postOESExp = /\x7F\x7F(.+)$/;
    let postOESMatch = postOESExp.exec(segmentsStr);
    if (postOESMatch !== null) {
        matches.endString = postOESMatch[1];
    }
    return matches;
}

function encodeHTMLEntities(string) {
    var entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;'
    };

    return String(string).replace(/[&<>"'`=\/]/g, function (s) {
        return entityMap[s];
    });

}

function escapeHTMLEntities(string) {

    return String(string).replace(/[&<>"'`=\/]/g, (val) =>{
        return `\\${val}`;
    });
}

function isEmail(email) {
    let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
    return regex.test(email);
}

function isValidPhone(phoneNumber) {
    return isValidNumber(phoneNumber);
}

function getGUID() {
    return window.LivingScript_0070 ? window.LivingScript_0070.guid.generateSync(): getUUID();
}

function tryParseJSON(jsonString) {
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns 'null', and typeof null === "object",
        // so we must check for that, too.
        if (o && typeof o === "object" && o !== null) {
            return o;
        }
    }
    catch (e) {
    }

    return false;
}

function isEmptyObject(obj) {
    return (obj // 👈 null and undefined check
        && Object.keys(obj).length === 0
        && Object.getPrototypeOf(obj) === Object.prototype);
}

function getUUID(uuidFormat) {
    uuidFormat = uuidFormat || false;

    //return 'id_' + this.Y.Crypto.UUID();

    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    if (uuidFormat) {
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    else {
        return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    }
}

function getAdjustedCurrentPEs(PE) {
    let parsedPE = parseOES(PE).segments;
    return [
        PE,
        createSimpleOES([parsedPE[2], parsedPE[3],parsedPE[2], parsedPE[3]])
    ];
}

async function getInputSuggestionsFromServer(data) {
    return sendComponentData(appId, "none", "getInputSuggestions", data);
}

async function sendPEEmail(data) {
    //return sendComponentData(appId, "none", "sendPEEmail", data);
    return callCommonServiceAPI("email-manager", "send", data);
}

async function getDomainEmailFromPE(fromPE, domain) {
    /*return sendComponentData(appId, "none", "getDomainEmailFromPE",
        Object.assign({fromPE}, domain ? { domain} : {}));*/
    return callCommonServiceAPI("simplia-api", "getDomainEmailFromPE", Object.assign({fromPE}, domain ? { domain} : {}));
}

async function createTraefikLinks(linkPEs, username) {
    return sendComponentData(appId, "none", "createTraefikLinks", {linkPEs, username});
}

async function getComponentByDisplayName(componentDN) {
    //return sendComponentData(appId, "none", "getComponentByDisplayName", {componentDN});
    return callCommonServiceAPI("simplia-api", "getComponentByDisplayName",{componentDN});
}

async function getComponent(componentId, edge) {
    /*return sendComponentData(appId, "none", "getComponent",
        Object.assign({componentId}, edge ? {edge} : {}));*/
    return callCommonServiceAPI("simplia-api", "getComponent", Object.assign({componentId}, edge ? {edge} : {}));
}

async function getMultipleComponents(componentIds) {
    return callCommonServiceAPI("simplia-api", "getMultipleComponents", {componentIds});
}

async function getGrantFromPE(GrantPE) {
    //return sendComponentData(appId, "none", "getGrantFromPE", {PE});
    return callCommonServiceAPI("simplia-api", "getGrantFromPE",{GrantPE});
}

async function getGrant(grantId) {
    //return sendComponentData(appId, "none", "getGrant", {grantId});
    return callCommonServiceAPI("simplia-api", "getGrant",{grantId});
}

async function sendComponentData(typeId, serverNodeId, command, commandData) {
    let url = `/simplia/components/${typeId}`;

    let data = {
        command,
        data: JSON.stringify(commandData)
    };

    let response = await fetch(
        url,
        {
            method: 'POST',
            body: new URLSearchParams(data),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            },
            mode: 'cors'
        }
    ).catch((e)=> {
        throw e;
    });

    let responseObj;
    if(response.ok && (responseObj = await response.json())) {
        return responseObj.data;
    }
    return false;
}

async function doTrinoQuery(query) {
    let url = `/DashboardWrapper/System/ServerSide/sqlQuery`;

    let response = await fetch(
        url,
        {
            method: 'POST',
            body: JSON.stringify({
                query,
                "source": "trino"
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            mode: 'cors'
        }
    ).catch((e)=> {
        console.log('doTrinoQuery', 'error:', e);
        throw e;
    });

    let responseObj;
    //console.log('doTrinoQuery', 'response:', response);
    if(response.ok && (responseObj = await response.json())) {
        //console.log('doTrinoQuery', 'res-json:', responseObj);
        return responseObj;
    }
    return false;

}

async function doDruidQuery(query) {
    let url = `/as/_FFFFFFFFFFFFFF20170326103602132008_/at/_FFFFFFFFFFFFFF20170326103602132008_/module/oxygen-druid/api/sqlQuery`;

    let data = {
       query
    };

    let response = await fetch(
        url,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            mode: 'cors'
        }
    ).catch((e)=> {
        throw e;
    });

    let responseObj;
    if(response.ok && (responseObj = await response.json())) {
        return responseObj;
    }
    return false;
}

async function getAllComponentsRoles(componentIds, ...args) {
    return getComponentsRoles(
        "select distinct GrantRightR as role, GrantRightID as componentId from OxygenDatabase.Grants where  GrantRightID in (" + componentIds.map(e => '?').join(",") + ")" + " and GrantType = 'Grant'",
        componentIds.map(e => e.id),
        componentIds,
        ...args
    );
}

async function getAvailableComponentsRoles(componentIds, toRight, toRightRole, ...args) {

    return getComponentsRoles(
        "select distinct GrantRightR as role, GrantRightID as componentId from OxygenDatabase.Grants where ( ToRightID = ? ) AND ( ToRightR = '*' OR ToRightR = ? ) AND GrantRightID in (" + componentIds.map(e => '?').join(",") + ")",
        [toRight, toRightRole].concat(componentIds.map(e => e.id)),
        componentIds,
        ...args
    );
}

async function runDBQuery(query, data, clientId, grantId) {
    let registerVal;
    clientId = clientId || ( (window.botStore && window.botStore.clients && window.botStore.clients[0]) ? window.botStore.clients[0].Client :
        '_FF07609117308816579277396602531608_');
    grantId = grantId || (window.LivingScript_0070 ? window.LivingScript_0070.context.grantId : '_FFFFFFFFFFFFFF16563664199331282052_');

    if(registerVal = await historyLib.register(
        {
            ClientId: clientId,
            GrantID: grantId
        })) {
        let {results} = await historyLib.action({

            ClientId: clientId,
            GrantID: grantId,
            Path: 'SQL/Query',
            query,
            data
        });

        return results;
    }
}

async function postHistoryNote({ToPE, ByPE}, note) {
    let geolocation = window.LivingScript_0070.globalContext.geolocation;
    let data = Object.assign({
        PK: getGUID(),
        ToPE,
        ByPE,
        FromPE: Cookies.get('gatewayPE'),
        CredentialPE: Cookies.get('credentialPE'),
        AgentOfPE: Cookies.get('agentOfPE'),
        GatewayPE: Cookies.get('gatewayPE'),
        Note: JSON.stringify({ String: note, Link: ''}),
        EventLabel: 'Message',
        EventTemplate: '_FFFFFFFFFFFFFF00001575595750910042_',
        NoteTemplate: "MessageNoteTemplate-0056",
        NoteTemplateID: "_FFFFFFFFFFFFFF00001633127893643639_",
        TaskID: getGUID(),
        TaskTemplateID: '_FF07508219919316956629008089530238_',
        SessionId: Cookies.get('sessionId')
    }, ( geolocation && geolocation.location) ? {
        Lat: geolocation.location.latitude,
        Long: geolocation.location.longitude,
        IPAddress: geolocation.traits.ip_address,
        City: geolocation.city.names.en,
        State: geolocation.postal.code,
        Country: geolocation.country.names.en,
        GeoIPLocation: JSON.stringify(geolocation),
        
    } : {});
    return await postHistoryMessage(data);
}

async function postHistoryUpdate(historyData, clientId, grantId) {
    return await postHistoryMessage(historyData, clientId, grantId, 'History/Update');
}

async function postHistoryMessage(historyData, clientId, grantId, path) {
    path = path || 'History/Add';
    let registerVal;
    clientId = clientId || ( (window.botStore && window.botStore.clients && window.botStore.clients[0]) ? window.botStore.clients[0].Client :
        '_FF07609117308816579277396602531608_');
    grantId = grantId || (window.LivingScript_0070 ? window.LivingScript_0070.context.grantId : '_FFFFFFFFFFFFFF16563664199331282052_');

    //console.log('postHistoryMessage', 'clientId:', clientId, ' - grantId:', grantId);
    if(registerVal = await historyLib.register(
        {
            ClientId: clientId,
            GrantID: grantId
        })) {
        //console.log('postHistoryMessage', 'historyData:', historyData);
        let historyItem = await historyLib.action(Object.assign({

            ClientId: clientId,
            GrantID: grantId,
            Path: path,

        }, historyData));

        return historyItem;
    }

}

async function getAttributionRecord(attributionId) {
    let rows = await runDBQuery(
        `select * from OxygenDatabase.Attribution where ID = ?`,
        [attributionId]
    );
    return rows.length ? rows[0] : null;
}

async function getAttributionBookmarks(attributionId, type) {
    let rows = await runDBQuery(
        `select ab.* from OxygenDatabase.AttributionBookmarks ab, OxygenDatabase.Attribution a where a.ID = ? AND a.AttributionID = ab.AttributionID ${type ? ' and Type = ?' : ''}`,
        [attributionId].concat(type ? type : [])
    );
    return rows.length ? (type ? rows[0] : rows) : null;
}

async function getComponentsRoles(sql, vals, componentIds, ...args) {
    let additionalRoles, ignoreRoles = [];
    if(args.length > 0) {
        additionalRoles = args[0];
    }
    if(args.length > 1 && Array.isArray(args[1])) {
        ignoreRoles = args[1];
    }


    let results = await runDBQuery(sql, vals);

    if(results && results.length) {
        let defaultRoleMap = componentIds.reduce((acc, val)=>{
            acc[val.id] = Array.isArray(val.defaultRole) ? val.defaultRole : [val.defaultRole];
            return acc;
        }, {});

        if(additionalRoles) {
            results = additionalRoles.concat(results);
        }

        let duplicateCheckMap = {}
        let roles = results.reduce((acc, val)=>{
            if(!acc[val.componentId]) {
                acc[val.componentId] = [];
                duplicateCheckMap[val.componentId] = {};
            }
            if(!ignoreRoles.includes(val.role) && !duplicateCheckMap[val.componentId][val.role]) {
                duplicateCheckMap[val.componentId][val.role] = 1;
                acc[val.componentId].push({
                    role: val.role,
                    selected: false
                });
            }
            return acc;
        }, {});

        //(Array.isArray(defaultRoleMap[val.componentId]) ? (defaultRoleMap[val.componentId].includes[val.ro]) : (defaultRoleMap[val.componentId] === val.role))
        Object.entries(defaultRoleMap).forEach((e)=>{
            let done = false;
            e[1].forEach((defaultRole)=>{
                roles[e[0]].forEach((componentRole)=>{
                    if(!done && componentRole.role === defaultRole) {
                        componentRole.selected = true;
                        done = true;
                    }
                });
            });
        })
        return roles
    }
    return [];
}

function addTemplateSaveOptions(...args) {
    addSaveAsFinalOption(...args);
    addSaveAsDraftOption(...args);
}

function getViewport(viewportId) {
    return window.devStore.viewPorts.find(e => e.viewportID === viewportId);
}

function addSaveAsFinalOption(viewportID, saveCB, extraRoles) {
    let formContainerObj = getViewport(viewportID).formContainer;
    window._formContainerObj = formContainerObj;
    window.DevMgr.addRightMemu(viewportID,{icon: 'fal fa-floppy-disk', title: 'Save As Final', callback: async (e)=>{
            formContainerObj.formContainerTitle = "Save As Final";
            formContainerObj.formRows.length = 0;
        formContainerObj.formRows.push(
            {
                type: "input",
                name: "Display Name Prefix",
                label: "Display Name Prefix",

                placeholder: ''
            },
            {
                type: "checkbox",
                name: "Save as Template",
                label: "Save as Template",
                labelPosition: "left"
            },{
                type: "checkbox",
                name: "Save as Child",
                label: "Save as Child",
                labelPosition: "left"
            },

            {
                    type: "button",
                    css: "spaced-button",
                    name: "save",
                    text: "Save",
                    size: "medium",
                    view: "flat",
                    color: "primary"
                }
        );

            formContainerObj.formContainer.showContainerAlignedToElementInViewport(
                document.getElementById(viewportID).querySelector(".main-panel-header-title"),
                'bottom',
                document.getElementById(viewportID).getBoundingClientRect()
            );

            await formContainerObj.formContainer.$nextTick();
            await formContainerObj.formContainer.$nextTick();


            formContainerObj.formContainer.formInstance.getItem("save").events.on("click", async ()=>{

                let byContext = getByContext(viewportID), viewportContext = getViewportContext(viewportID);

                let atComponent = await getComponent(viewportContext.client.ClientRight);
                let componentId = atComponent.Node;
                if(atComponent.MetaType === 'Template') {
                    componentId = getGUID();
                    await createComponent(atComponent.Node, {}, { Node: componentId, MetaType: 'Draft', toPE: viewportContext.contextPE });
                }

                //console.log('saveAsFinal', 'asTemplate:', formContainerObj.formContainer.formInstance.getItem("Save as Template").getValue());
                let params = {
                    isTemplate: formContainerObj.formContainer.formInstance.getItem("Save as Template").getValue(),
                    DNPrefix: formContainerObj.formContainer.formInstance.getItem("Display Name Prefix").getValue(),
                    isChild: formContainerObj.formContainer.formInstance.getItem("Save as Child").getValue() || false,
                    contextPE: byContext.contextPE,
                    newAdminGrantToPE: byContext.contextPE,
                    componentId,
                    initialContext: {}
                };
                await saveCB(params.initialContext, componentId);
                //console.log('doing callComponent', 'params:', params);
                callComponent("saveAsFinal",
                    params,
                    atComponent.Type,
                    window.LivingScript_0070.globalContext.serverId,
                    viewportContext.viewport.MessageBox.componentData.propagatorId,
                    (error, message)=>{

                    formContainerObj.formContainer.hide();
                    window.BotServices.PullPE(
                        {
                            ByClientID : viewportContext.client.Client,
                            InitialContext : {
                                ByClientID : viewportContext.client.Client
                            },
                            OpenPE : message.data.newAdminGrant.GrantPE
                        }
                    );
                    if(Array.isArray(extraRoles)) {
                        extraRoles.forEach(async (e)=>{
                            await oxygenUtil.putGrant({
                                onObject: componentId,
                                onRole: e,
                                toPE: byContext.contextPE
                            });
                        });
                    }
                    window.DevMgr.closeViewPort(viewportID);
                });
            });
        }});
}

function addSaveAsDraftOption(viewportID) {
    window.DevMgr.addRightMemu(viewportID,{icon: 'fal fa-floppy-disk', title: 'Save As Draft', callback: (e)=>{

        }});
}

function getByContext(viewportID) {
    let client = window.botStore.getClientByViewPort(viewportID);
    let clientInitialContext = oxygenUtil.tryParseJSON(client.InitialContext);

    if(clientInitialContext && clientInitialContext.ByClientID) {
        let byClient = window.devStore.clients.find(e => clientInitialContext.ByClientID === e.Client);

        let byClientContext = oxygenUtil.tryParseJSON(byClient.ClientContext);
        return {
            client: byClient,
            clientContext: byClientContext,
            contextPE: getContextPE(byClientContext.GrantPE)
        }
    }
    return null;
}

function getContextPE(PE) {
    let parsedPE = oxygenUtil.parseOES(PE).segments;
    return (window.top.LivingScript_0070.context.credentialId  === parsedPE[3]) ?
        ( oxygenUtil.createSimpleOES(['admin', parsedPE[3], 'admin', parsedPE[3] ]) ): PE;
}

function addSaveAsTemplateOption(viewportID) {
    window.DevMgr.addRightMemu(viewportID,{icon: 'fal fa-floppy-disk', title: 'Save As Template', callback: (e)=>{

        }});
}

async function getEmailAddressInfo(emailAddress, password) {

    /*return sendComponentData(
        appId,
        "none",
        "createEmailContact",
        {
            emailAddress,
            SkipNotification: true
        }
    ).catch((e)=>{
        throw e;
    });

     */
    return callCommonServiceAPI(
        "simplia-api",
        "createEmailContact",
        Object.assign({
            emailAddress,
            SkipNotification: true
        }, password ? { password } : {})
    ).catch((e)=>{
        console.error('createEmailContact', 'Error:', e);
        throw e;
    });

}

async function getPhoneInfo(phoneNumber, password) {

    /*return sendComponentData(
        appId,
        "none",
        "createPhoneContact",
        {
            phoneNumber,
            SkipNotification: true
        }
    ).catch((e)=>{
        throw e;
    });

     */
    return callCommonServiceAPI(
        "simplia-api",
        "createPhoneContact",
        Object.assign({
            phoneNumber,
            SkipNotification: true
        }, password ? { password } : {})
    ).catch((e)=>{
        console.error('createPhoneContact', 'Error:', e);
        throw e;
    });

}

async function sendPhoneMessages(byPE, phoneMessages, additionalParams) {

    let deepLinks = [];
    additionalParams = additionalParams || {};
    if(phoneMessages.length) {
        async.each(phoneMessages, (phoneMessage, callback) => {
            let parsedGatewayPE = parseOES(phoneMessage.phoneRow.data.phoneInfo.personalGatewayPE).segments;
            let linkPEs = {
                subject: { PE: createSimpleOES([ parsedGatewayPE[2], parsedGatewayPE[3], phoneMessage.subjectRole, phoneMessage.subjectId  ]) }
            };

            async.waterfall([
                /*
                (callback) => {
                    mainPanel.putGrant(Object.assign({}, phoneMessage.envelope, {
                        onObject: phoneMessage.subjectId,
                        onRole: phoneMessage.subjectRole,
                        toPE: phoneMessage.phoneRow.data.phoneInfo.personalGatewayPE
                    }), callback);
                },*/
                (callback) => {
                    if(phoneMessage.htmlBodyTip) {
                        putGrant(Object.assign({}, phoneMessage.envelope, {
                            onObject: phoneMessage.htmlBodyTip.Node,
                            onRole: 'admin',
                            toPE: createSimpleOES(['bot', parsedGatewayPE[1], parsedGatewayPE[2], parsedGatewayPE[3]])
                        }), (error, offerGrant)=>{
                            if(error)  {
                                return callback(error);
                            }
                            linkPEs.body = {PE: offerGrant.GrantPE};
                            callback();
                        });
                    }
                    else {
                        callback();
                    }
                },
                (callback) => {
                    if(phoneMessage.phoneAttachmentGrants) {
                        linkPEs.attachments = [];
                        async.each(phoneMessage.phoneAttachmentGrants, (grantObj, callback) => {
                            let parsedToPE = parseOES(grantObj.grant.toPE).segments;
                            linkPEs.attachments.push({
                                PE: createSimpleOES([parsedToPE[2], parsedToPE[3], grantObj.grant.onRole, grantObj.grant.onObject]),
                                name: grantObj.name,
                                GrantType: 'Offer'
                            });
                            callback();
                        }, (error) => {
                            callback(error);
                        });
                    }
                    else {
                        callback();
                    }
                },
                async () => {
                    let {links} = await callCommonServiceAPI(
                        "simplia-api",
                        "createDeepLinks",
                        {
                            linkPEs
                        }
                    );

                    return links;
                },
                (links, callback) => {
                    deepLinks.push(links);
                    /*
                    mainPanel.callComponent(
                        this.config.appId,
                        "SendSMS",
                        {
                            from: this.config.sms.senderNumber,
                            to: phoneMessage.phoneRow.data.phoneNumber,
                            body: this.createPhoneTextBody(phoneMessage, links),
                        },
                        callback
                    );
                     */
                    let parsedByPE = parseOES(byPE).segments;
                    let params = Object.assign({
                        to: {
                            tope: [],
                            componentid: [],
                            email: [],
                            phone: [phoneMessage.phoneRow.data.phoneNumber]
                        },
                        thread: parsedByPE[1],
                        subject: parsedByPE[3],
                        message: createPhoneTextBody(phoneMessage, links),
                        mode: 'send',
                        bype: byPE
                    }, additionalParams);
                    //console.log('sendPhoneMessages', 'params:', params);
                    window.BotServices.callManagerExec(params, callback);
                }
            ], callback)

        }, (error) => {
            return { deepLinks};
        });
    }

}

async function sendEmailMessages(fromPE, emailMessages) {
    //console.log('sendEmailMessages', 'emailMessages:', emailMessages);
    let deepLinks = [];
    if(emailMessages.length) {
        let allToAddresses = emailMessages.map(e => e.emailRow.data.emailAddress);
        async.eachSeries(emailMessages, (emailMessage, callback) => {
            let parsedGatewayPE = parseOES(emailMessage.emailRow.data.emailInfo.personalGatewayPE).segments;
            let linkPEs = {
                thread: { PE: createSimpleOES([ parsedGatewayPE[2], parsedGatewayPE[3], emailMessage.threadRole, emailMessage.threadId  ]), GrantType: 'Offer' },
                subject: { PE: createSimpleOES([ emailMessage.threadRole, emailMessage.threadId, emailMessage.subjectRole, emailMessage.subjectId  ]), GrantType: 'Offer' }
            };

            async.waterfall([
                /*
                (callback) => {
                    mainPanel.putGrant(Object.assign({}, emailMessage.envelope, {
                        onObject: emailMessage.subjectId,
                        onRole: emailMessage.subjectRole,
                        toPE: emailMessage.emailRow.data.emailInfo.personalGatewayPE
                    }), callback);
                },
                (subjectGrant, callback) => {

                 */
                (callback) => {
                    if(emailMessage.htmlBodyTip) {
                        putGrant(Object.assign({}, emailMessage.envelope, {
                            onObject: emailMessage.htmlBodyTip.Node,
                            onRole: this.config.roles.admin,
                            toPE: createSimpleOES([this.config.roles.bot, parsedGatewayPE[1], parsedGatewayPE[2], parsedGatewayPE[3]])
                        }), (error, offerGrant)=>{
                            if(error)  {
                                return callback(error);
                            }
                            linkPEs.body = {PE: offerGrant.GrantPE};
                            callback();
                        });
                    }
                    else {
                        callback();
                    }
                },
                (callback) => {
                    //console.log('sendEmailMessages', 'emailMessage.emailAttachmentGrants:', emailMessage.emailAttachmentGrants);
                    if(emailMessage.emailAttachmentGrants) {
                        linkPEs.attachments = [];
                        async.each(emailMessage.emailAttachmentGrants, (grantObj, callback) => {
                            //let parsedToPE = parseOES(grantObj.grant.toPE).segments;
                            linkPEs.attachments.push({
                                PE: createSimpleOES(['*','*', grantObj.grant.onRole, grantObj.grant.onObject]),
                                name: grantObj.name,
                                linkParams: {
                                    GrantType: 'Offer',
                                    URLContext: {onLoad : [{open : "true"}]}
                                }
                            });
                            callback();
                        }, (error) => {
                            callback(error);
                        });
                    }
                    else {
                        callback();
                    }
                },
                async () => {
                    //console.log('sendEmailMessages', 'linkPEs:', linkPEs);
                    let {links} = await callCommonServiceAPI(
                        "simplia-api",
                        "createDeepLinks",
                        {
                            linkPEs
                        }
                    );
                    //console.log('links:', links);
                    return links;
                    //callback(null, links);
                },
                (links, callback) => {
                    //console.log('calling createEmailHtmlBody');
                    createEmailHtmlBody(emailMessage, links, (error, htmlBody) => {
                        callback(error, links, htmlBody);
                    });
                },
                async (links, htmlBody) => {
                    let cal;
                    if(emailMessage.scheduleTimes) {
                        cal = ical();
                        let event = cal.createEvent({
                            start: emailMessage.scheduleTimes.T1,
                            end: emailMessage.scheduleTimes.T2,
                            summary: emailMessage.subject,
                            description: createEmailTextBody(emailMessage, links)
                        });
                        event.attendees(allToAddresses.map(e => ({email: e})));
                        let {email} = await getDomainEmailFromPE(
                            fromPE,
                            emailMessage.domain
                        )
                        if(email) {
                            event.organizer({email, name});
                        }
                        return {links, htmlBody, cal};
                        //callback(null, links, htmlBody, cal);

                    }
                    else {
                        return {links, htmlBody, cal};
                        //callback(null, links, htmlBody, cal);
                    }
                },
                async (results) => {
                    let {links, htmlBody, cal} = results;
                    deepLinks.push(links);

                    await sendPEEmail(
                        Object.assign({
                                replyPE: fromPE,
                                toAddress: emailMessage.emailRow.data.emailAddress,
                                subject: emailMessage.subject,
                                body: createEmailTextBody(emailMessage, links),
                                htmlBody,
                            },
                            emailMessage.bccEmails ? { bcc: emailMessage.bccEmails } : {},
                            cal ? { icalEvent: { content: cal.toString() } } : {},
                            emailMessage.domain ? { domain: emailMessage.domain } : {},
                            //( mainPanel.attributionRecord && mainPanel.attributionRecord.alias_email ) ? { fromEmail: mainPanel.attributionRecord.alias_email } : { fromPE: mainPanel.getInboxContextPE()},
                            { fromPE },
                            emailMessage.causingEventId ? { causingEventId: emailMessage.causingEventId} : {},
                            emailMessage.inReplyTo ? { inReplyTo: emailMessage.inReplyTo} : {},
                            emailMessage.fromAttributionId ? { fromAttributionId: emailMessage.fromAttributionId } : {})
                    );

                }
            ], callback)

        }, (error)=>{
           return {deepLinks};
        });
    }
}

function createEmailHtmlBody(emailMessage, links, callback) {
    //let body = `<span>Subject:${links.subject.ShortURL}</span><br/><br/>`;
    let body = '';

    async.waterfall([
        (callback) => {
            fixEmbeddedURLs(emailMessage, emailMessage.emailRow.data.emailAddress, callback);
        },
        (fixedFullMessage, callback) => {
            fixedFullMessage = fixedFullMessage.replaceAll(/[\n\r]/g,'<br/>');
            body += fixedFullMessage;
            if(links.attachments) {
                body += `<br/><br/>Attachments:<br/><br/>`;
                links.attachments.forEach((attachmentLink)=>{
                    body += `${attachmentLink.name}: ${attachmentLink.urlData.ShortURL}<br/>`;
                });
            }
            callback(null, body);
        }
    ], callback);
}

function fixEmbeddedURLs(message, username, callback) {

    let fullMessage = message.fullMessage;
    let regex = /<a\s+href="javascript:\s+void\(0\)"\s+data-pe="([^"]+)"\s+class="grant-link">([^<]+)<\/a>/g;
    let matches, newContent = fullMessage;

    async.whilst(
        (callback) =>{
            callback(null, matches = regex.exec(fullMessage));
        },
        async () => {
            //let parsedCurrentEmbeddedPE = this.parseOES(matches[1]).segments;

            let {links} = await callCommonServiceAPI("simplia-api","createDeepLinks",{ linkPEs: { embedded: {PE: matches[1] } } });
            newContent = newContent.replace(matches[0], `<a href="${links.embedded.ShortURL}" data-pe="${matches[1]}" class="grant-link">${matches[2]}</a>`);
        },
        (error) => {
            callback(error, newContent);
        }
    );

}

function createEmailTextBody(emailMessage, links) {
    let body = `Subject:${links.subject.ShortURL}\n\n`;

    if(links.body) {
        body += `Body:${links.body.ShortURL}\n\n`;
    }
    body += emailMessage.noteMessage;
    if(links.attachments) {
        body += `\n\nAttachments:\n\n`;
        links.attachments.forEach((attachmentLink)=>{
            body += `${attachmentLink.name}: ${attachmentLink.urlData.ShortURL}\n`;
        });
    }
    return body;
}

function createPhoneTextBody(phoneMessage, links) {
    //let body = `Sub:${links.subject.ShortURL}\n`;
    let body = '';

    if(links.body) {
        body += `Body:${links.body.ShortURL}\n`;
    }
    body += phoneMessage.noteMessage;
    if(links.attachments) {
        body += `\nAtts:\n`;
        links.attachments.forEach((attachmentLink)=>{
            body += `${attachmentLink.name}: ${attachmentLink.urlData.ShortURL}\n`;
        });
    }
    return body;
}

async function getInputSuggestions(input, callback) {

    let transactionalFn = (transactionalData) => {
        callback(null, transactionalData);
    }, transactionId = this.getUUID();
    mainPanel.addTransaction(transactionalFn, transactionId);


    mainPanel.callComponent(
        this.config.appId,
        "getInputSuggestions",
        { input, context: mainPanel.componentContext, transactionId },
        ()=>{
            mainPanel.removeTransaction(transactionId);
        }
    );
}

function initializePropagator(containerId, componentId, onGCOIDsListener, callback) {
    if(window.LivingScript_0070 && window.LivingScript_0070.propagator) {
        window.LivingScript_0070.propagator.initialize({
            ContainerOID: containerId,
            ComponentOID: componentId,
            onGetContainedOIDs: onGCOIDsListener
        }, callback);
    }
    else {
        callback('No propagator library found');
    }
}

var messageTypes = {
    "error": "Error",
    "success": "Success",
    "api": "API",
    "notice": "Notice",
    "alert": "Alert",
    "news": "News",
    "raisedEvent": "RaisedEvent",
    "oxygenEvent": "OxygenEvent"
};

var _containerId = window.sessionStorage.getItem('browserTabId')

var transactions = {};

function addTransaction(fn, id) {
    transactions[id] = fn;
}

function removeTransaction(id) {
    delete transactions[id];
}

function handleTransactionalData(data) {
    let fn;
    if(data.transactionId && (fn = transactions[data.transactionId])) {
        //console.log('handleTransactionalData, data:', data);
        fn(data.transactionalData);
    }
}

function setupPropagator(componentId, messageListeners, callback) {
    let propagator;
    if (window.LivingScript_0070 && (propagator = window.LivingScript_0070.propagator)) {
        async.waterfall([
            propagator.addPort.bind(propagator, {ComponentOID: componentId, PortType: 'Input'}),
            (inputPort, callback) => {
                propagator.listenToInputPort({
                    InputPort: inputPort,
                    Listener: (payload) => {
                        //console.log('propagator-payload:', payload);
                        if (payload.Message.MessageType === messageTypes.notice && messageListeners.notice) {
                            return messageListeners.notice(payload.Message);
                        }

                        if (payload.Message.MessageType === messageTypes.news && messageListeners.news) {
                            return messageListeners.news(payload.Message);
                        }

                        if (payload.Message.MessageType === messageTypes.oxygenEvent && messageListeners.oxygenEvent) {
                            return messageListeners.oxygenEvent(payload.Message);
                        }

                        if (payload.Channel && messageListeners.channel) {
                            return messageListeners.channel(payload.Channel, payload.Message);
                        }


                        if(payload.Message.MessageBody.command && payload.Message.MessageBody.command === 'getTransactionalData') {
                            //console.log('got transactional data:', payload.Message.MessageBody.data);
                            return handleTransactionalData(payload.Message.MessageBody.data);
                        }

                        if(payload.Message.MessageBody.replyGuid) {
                            return handleReplyMessage(payload.Message);
                        }
                        if(messageListeners.default) {
                            messageListeners.default(payload.Message);
                        }
                    }
                }, (error)=>{
                    callback(error, inputPort);
                });
            },
            (inputPort, callback) => {
                let channel = propagator.getDefaultChannel({
                    ComponentOID: componentId,
                    ContainerOID: _containerId
                });

                propagator.subscribeToChannel({
                    Channel: channel,
                    InputPort: inputPort
                });


                callback()
            }
        ], callback)
    }
    else {
        callback('No propagator instance found')
    }
}


function handleReplyMessage(fullMessage) {
    //console.log('handleReplyMessage', 'replyCallback:', window._replyCallbacks[fullMessage.MessageBody.replyGuid]);
    let message = fullMessage.MessageBody;
    if ((typeof window._replyCallbacks[message.replyGuid] !== "undefined") && (typeof window._replyCallbacks[message.replyGuid].callback === "function")) {
        if (fullMessage.MessageType &&
            (fullMessage.MessageType === messageTypes.error) &&
            (window._replyCallbacks[message.replyGuid].calleeId) &&
            (typeof window._replyCallbacks[message.replyGuid].message !== "undefined")) {
            //return this.handleErrorReply(this.window._replyCallbacks[message.replyGuid].calleeId, this.window._replyCallbacks[message.replyGuid].message, fullMessage, this.window._replyCallbacks[message.replyGuid].callback);
            return;
        }

        let replyCallback = window._replyCallbacks[message.replyGuid];
        delete window._replyCallbacks[message.replyGuid];

        if ((typeof fullMessage.MessageBody.data !== "undefined") && (typeof fullMessage.MessageBody.data.error !== "undefined") && fullMessage.MessageBody.data.error) {
            replyCallback.callback(fullMessage.MessageBody.data.errorData, message);
        }
        else {
            replyCallback.callback(null, fullMessage.MessageBody);
        }
    }
}

function publishPropagator(calleeId, serverId, message, ...args) {

    let callback = args[args.length - 1], publishChannel = false;
    if(args.length > 1) {
        publishChannel = args[0];
    }
    if(window.LivingScript_0070 && window.LivingScript_0070.propagator) {

        message.MessageBody.ReplyContainer = _containerId;
        if (publishChannel) {
            propagatorChannelPublish(
                calleeId,
                message,
                callback
            );
        } else {
            propagatorPublishDirect(
                window.LivingScript_0070.propagator.getDefaultInputPort({
                    ComponentOID: calleeId
                }),
                message,
                serverId,
                callback
            );
        }
    }
    else {
        callback('No propagator library found');
    }
}

function propagatorChannelPublish(channel, message, ...args) {
    let callback = args[args.length - 1];
    let options = (args.length > 1) ? args[0] : {};
    let messageOptions = {
        Payload: {
            Channel: channel,
            Message: message
        }
    };

    for(let i in options) {
        messageOptions[i] = options[i];
    }

    window.LivingScript_0070.propagator.publish(messageOptions, (error, data) => {
        if(callback) {
            callback(error, data);
        }
    });
}

function propagatorPublishDirect(inputPort, message) {
    let callback = arguments[arguments.length - 1], containerId = null;
    if(arguments.length > 3) {
        containerId = arguments[2];
    }

    let params = {
        InputPort: inputPort,
        Payload: {
            Message: message
        },
        Debug: true
    };
    if(containerId) {
        params.ContainerOID = containerId
    }


    window.LivingScript_0070.propagator.publishDirect(params, (...args)=>{
        callback.apply(null, args);
    });
}

if(!window._replyCallbacks) {
    window._replyCallbacks = {};
}


function callComponent(command, data, componentId, serverId, senderComponentId, callback) {
    let propagator;
    if (window.LivingScript_0070 && (propagator = window.LivingScript_0070.propagator)) {
        async.waterfall([
            (callback) => {
                if(!window._propagator[propagator.getDefaultInputPort({ComponentOID: senderComponentId})]) {
                    setupPropagator(senderComponentId, {}, callback);
                }
                else {
                    callback();
                }
            },
            (callback) => {
                let publishChannel = false;

                let message = {
                    MessageType: messageTypes.api,
                    MessageBody: {
                        command,
                        data,
                        ReplyTo: propagator.getDefaultChannel({ComponentOID: senderComponentId, ContainerOID: _containerId}),
                        ReplyPort: propagator.getDefaultInputPort({ComponentOID: senderComponentId})
                    }
                };

                let replyGuid = getUUID();
                message.MessageBody.replyGuid = replyGuid;

                window._replyCallbacks[replyGuid] = {
                    calleeId: componentId,
                    message,
                    callback
                };

                if (serverId || publishChannel) {
                    let params = [ componentId, serverId, message];
                    if(publishChannel) {
                        params.push(publishChannel);
                    }
                    params.push(()=>{});
                    //console.log('callComponent', 'publish-params:', params);
                    publishPropagator(...params);
                    if (message.MessageBody.noReply) {
                        callback();
                    }
                }
            }
        ], callback);
    }
    else {
        callback('Propagator not available');
    }
}

function createGrantPEFromGrant(grant) {
    return createSimpleOES([grant.GrantLeftR, grant.GrantLeftID, grant.GrantRightR, grant.GrantRightID]);
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function createScriptTag(url) {
    const scriptTag = document.createElement('script');
    scriptTag.src = url;
    scriptTag.setAttribute('charset', 'utf-8');
    document.head.appendChild(scriptTag);
}

function parsePhone(phoneNumber) {
    return parsePhoneNumberFromString(phoneNumber, 'US').format('E.164');
}

async function createComponent(templateId, contextData, optionalArgs) {
    return sendComponentData(appId, "none", "createComponentCall", {
        templateId,
        contextData,
        optionalArgs
    });
}

async function callServerAPI(command, commandData) {
    return sendComponentData(appId, "none", command, commandData);
}

function getDNPrefix(str) {
    let regEx = /^(.+)-\d+$/;
    let matches = regEx.exec(str);
    if(matches && matches.length) {
        return matches[1];
    }
    return str;
}

function getViewportContext(viewportId) {
    //console.log('getViewportContext', 'viewportId:', viewportId);
   let client, viewport, contextPE, grantId;

    if(window.botStore && viewportId) {
        //console.log('getViewportContext', 'client:',  window.botStore.getClientByViewPort(viewportId));
        client = window.botStore.getClientByViewPort(viewportId);
        viewport = getViewport(viewportId);
    }
    else {
        client =  { Client: '_FF07609117308816579277396602531608_'};
    }

    if(window.botStore && viewportId) {
        contextPE = (window.LivingScript_0070.context.credentialId  === client.ClientRight) ?
            ( createSimpleOES(['admin', client.ClientRight, 'admin', client.ClientRight]) ):
            createSimpleOES([client.ClientLeftRole, client.ClientLeft, client.ClientRightRole, client.ClientRight]);
        }
    else {
        contextPE = createSimpleOES(['admin', '_FFFFFFFFFFFFFF00001567723986493004_', 'admin', '_FFFFFFFFFFFFFF00001567723986493004_']);
    }
    let clientContext;
    grantId = (client && client.ClientContext && (clientContext = tryParseJSON(client.ClientContext)) && clientContext.GrantID ) ?
        clientContext.GrantID :
        ( window.LivingScript_0070 ? window.LivingScript_0070.context.grantId : '_FFFFFFFFFFFFFF16563664199331282052_');

    return { client, viewport, contextPE, grantId, clientContext };
}

async function putOfferGrant(grantData) {
    return await callCommonServiceAPI('simplia-api', 'putOfferGrant', grantData);
}

async function putRequestGrant(grantData) {
    return await callCommonServiceAPI('simplia-api', 'putRequestGrant', grantData);
}

async function putGrant(grantData) {
    return await callCommonServiceAPI('simplia-api', 'putGrant', grantData);
}

async function acceptGrant(grantData) {
    return await callCommonServiceAPI('simplia-api', 'acceptGrant', grantData);
}

async function denyGrant(grantData) {
    return await callCommonServiceAPI('simplia-api', 'denyGrant', grantData);
}

async function revokeGrantById(grantId) {
    return await callCommonServiceAPI('simplia-api', 'revokeGrantById', {grantId});
 }

async function updateGrant(grantData) {

}


if(!window.segmentHistoryIds) {
    window.segmentHistoryIds = {};
}

let clientEvents =  {
    "sessionStart": {
        "name": "SessionStart",
            "segmentTemplate": "_FFFFFFFFFFFFFF00001608174223355002_"
    },
    "sessionEnd": {
        "name": "SessionEnd",
            "segmentTemplate": "_FFFFFFFFFFFFFF00001608174244470002_"
    },
    "segmentStart": {
        "name": "SegmentStart",
            "segmentTemplate": "_FFFFFFFFFFFFFF00001608174149233002_"
    },
    "segmentEnd": {
        "name": "SegmentEnd",
            "segmentTemplate": "_FFFFFFFFFFFFFF00001608174168084002_"
    },
    "clickEvent": {
        "name": "ClickEvent",
            "segmentTemplate": "_FFFFFFFFFFFFFF00001608174257858002_"
    },
    "OrderFormOpened": {
        "name": "OrderFormOpened"
    },
    "OrderForm": {
        "name": "OrderForm"
    },
    "OrderFormCompleted": {
        "name": "OrderFormCompleted"
    },
    "LinkOpened": {
        "name": "LinkOpened"
    }
},
    eventTemplates = {
    "clientComponentEvents": "_FFFFFFFFFFFFFF00001575592773802029_",
        "analyticsEvents": "_FFFFFFFFFFFFFF00001575595750910019_",
        "serverComponentEvents": "_FFFFFFFFFFFFFF00001575595750910024_",
        "botEvents": "_FFFFFFFFFFFFFF00001575595750910030_",
        "behaviourEvents": "_FFFFFFFFFFFFFF00001575595750910036_",
        "conferenceEvents": "_FFFFFFFFFFFFFF00001575595750910042_",
        "lbsEvents": "_FFFFFFFFFFFFFF00001575595750910048_",
        "defaultBehaviorEvents": "_FFFFFFFFFFFFFF00001591059071672068_",
        "actionEvents": '_FFFFFFFFFFFFFF00001590859647734210_',
        "offerEvents": '_FFFFFFFFFFFFFF00001590851948799077_',
        "requestEvents": '_FFFFFFFFFFFFFF00001590851948799085_',
        "grantEvents": '_FFFFFFFFFFFFFF00001590851948799071_',
        "inviteEvents": '_FFFFFFFFFFFFFF00001590869786958135_',
        "joinEvents": '_FFFFFFFFFFFFFF00001590869786958141_',
        "connectEvents": '_FFFFFFFFFFFFFF00001590859647734238_',
        "leadEvents": '_FFFFFFFFFFFFFF00001563207715828312_',
        "marketplaceEvents": '_FFFFFFFFFFFFFF00001563207715828304_',
        "messageEvents": '_FFFFFFFFFFFFFF00001575595750910042_',
        "contactOfferAlerts": "_FF07213418413620210218002544809000_",
        "contactRequestEvents": "_FFFFFFFFFFFFFF00001615744024145766_",
        "emailEvents": "_FFFFFFFFFFFFFF00001628734321742588_",
        "tasksEvents": "_FFFFFFFFFFFFFF00001647473012838923_"
},
    eventRegistrants = {
    "simpliaBrowserApp": "_FFFFFFFFFFFFFF00001575594505037002_",
        "behaviorRegistrant": "_FFFFFFFFFFFFFF00001591057353065361_",
        "messageRegistrant": "_FFFFFFFFFFFFFF00001575882222359002_",
        "alertsRegistrant": "_FFFFFFFFFFFFFF00001590859647734204_",
        "emailsRegistrant": "_FFFFFFFFFFFFFF00001628734321742587_"
};

async function recordAnalyticsEvent(eventName, customAttributes, historyData) {

    let eventTypeInfo =  Object.values(clientEvents).find(e => (e.name === eventName));
    //console.log('recordAnalyticsEvent', 'eventName:', eventName, ' - customAttributes:', customAttributes, ' - eventTypeInfo:', eventTypeInfo);

    /*console.log('recordAnalyticsEvent', 'name:', (eventTypeInfo.name === clientEvents.segmentEnd.name),
        ' all:', window.segmentHistoryIds,
        ' segment: ', window.segmentHistoryIds[customAttributes.segmentId],
        ' id:',  window.segmentHistoryIds[customAttributes.segmentId] ? window.segmentHistoryIds[customAttributes.segmentId].id : '');

     */
    if(eventTypeInfo.name === clientEvents.segmentEnd.name &&
        (window.segmentHistoryIds[customAttributes.segmentId] && window.segmentHistoryIds[customAttributes.segmentId].id)) {
        if(await historyLib.register(
            {
                ClientId: historyData.ClientId,
                GrantID: historyData.GrantID
            })) {
            await historyLib.action({
                ClientId: historyData.ClientId,
                GrantID: historyData.GrantID,
                Path: 'History/Update',
                PK: window.segmentHistoryIds[customAttributes.segmentId].id,
                EventLabel: 'SegmentEnd',
                EndTime: new Date().toISOString(),
                SegmentTemplate: eventTypeInfo.segmentTemplate,
                SegmentJSON: JSON.stringify(customAttributes) +
                    ( (window.segmentHistoryIds[customAttributes.segmentId] && window.segmentHistoryIds[customAttributes.segmentId].json) ?
                        window.segmentHistoryIds[customAttributes.segmentId].json : '')
            });

        }
    }
    else {
        if(await historyLib.register(
            {
                ClientId: historyData.ClientId,
                GrantID: historyData.GrantID
            })) {
            let historyItem = await historyLib.action(Object.assign({}, historyData, {
                ClientId: historyData.ClientId,
                GrantID: historyData.GrantID,
                Path: 'History/Add',
                EventTemplate: eventTemplates.clientComponentEvents,
                EventLabel: eventTypeInfo.name,
                Registrant: eventRegistrants.simpliaBrowserApp,
                BeginTime: new Date().toISOString(),
                SegmentId: customAttributes.segmentId,
                SegmentParentId: customAttributes.segmentRootId,
                SegmentRootId: customAttributes.segmentRootId,
                SegmentTemplate: eventTypeInfo.segmentTemplate,
                SegmentJSON: JSON.stringify(customAttributes)
            }));

            //console.log('recordAnalyticsEvent', 'historyItem:', historyItem);
            if(eventTypeInfo.name === clientEvents.segmentStart.name) {
                window.segmentHistoryIds[customAttributes.segmentId] = { id: historyItem.PK, json: JSON.stringify(customAttributes) };
            }

            return historyItem;
        }


    }
}

if(!window.eventMap) {
    window.eventMap = new Map();
}
function setupClientEventsListeners(element, client) {
    if(!window.eventMap.get(element)) {
        window.eventMap.set(element, true);
        //console.log('setupClientEventsListeners', 'element:', element);
        element.addEventListener('focus', async (event)=>{
            let attrName;
            let viewportElem = ((attrName = element.getAttribute('name')) && (attrName === 'oxygen-viewport') && element) ||
                element.closest('div[name="oxygen-viewport"]');
            //console.log('setupClientEventsListeners-listener', 'viewportElem:', viewportElem);
            //if(viewportElem) {
            //    window.oxygenUtil.putViewportOnTop(viewportElem.id);
            //}
            if(!window.currentFocusedClient) {
                //console.log('SegmentStart', 'current:', window.currentFocusedClient);
            }
            else {
                /*console.log('SegmentStart', 'current:', window.currentFocusedClient.Client,
                    ' - current-segment:', window.currentFocusedClient.currentSegment);

                 */
            }
            //console.log('SegmentStart', 'client:', client.Client, ' - client-segment:', client.currentSegment);

            if(window.currentFocusedClient && (window.currentFocusedClient.Client !== client.Client )) {
                await recordAnalyticsEvent('SegmentEnd', {
                    segmentId: window.currentFocusedClient.currentSegment,
                    segmentRootId: window.currentFocusedClient.historyData.SegmentId
                }, window.currentFocusedClient.historyData);
                //console.log('SegmentStart', 'segmentEnd done');
            }

            /*console.log('SegmentStart', 'doSegmentStart?', !window.currentFocusedClient ? 'no current' :
                (window.currentFocusedClient.Client !== client.Client ));

             */
            if(!window.currentFocusedClient || (window.currentFocusedClient.Client !== client.Client )) {
                client.currentSegment = getGUID();
                window.currentFocusedClient = client;
                //console.log('SegmentStart', 'setting focusedClient:', window.currentFocusedClient.Client);
                let results = await recordAnalyticsEvent('SegmentStart', {
                    segmentId: client.currentSegment,
                    segmentRootId: client.historyData.SegmentId
                }, client.historyData);

                //console.log('SegmentStart', 'results:', results);
            }
        });

        element.addEventListener('click', (event)=>{
            //console.log('click-event', 'event:', event);
            recordAnalyticsEvent('ClickEvent', {
                segmentId: client.currentSegment,
                segmentRootId: client.historyData.SegmentId
            }, client.historyData);


            let deskContext, deskId;
            if((deskContext = tryParseJSON(client.DeskContext)) && deskContext.Node) {
                deskId = deskContext.Node.ID;
            }

            let html;
            //console.log('click-event', 'event.target:', event.target);
            if(event.target) {
                //console.log('click-event', 'html:', event.target.innerHTML);
                html = event.target.innerHTML;
            }

            /*
            console.log('aiPublish', 'data:', Object.assign({
                ContextPE: client.historyData.GrantPE,
                AttributionId: client.historyData.AttributionId, MeetingId: client.historyData.MeetingId},deskId ? { DeskId: deskId} : {}, html ? { HTML: html} : {}  ));
             */

            window.BotServices.aiPublish({ClientID : client.Client, EventType : 'Click', Data : Object.assign({
                ContextPE: client.historyData.GrantPE,
                    AttributionId: client.historyData.AttributionId, MeetingId: client.historyData.MeetingId},deskId ? { DeskId: deskId} : {}, html ? { HTML: html} : {} )}, () =>{});


        });
    }

}

function setHistoryData(client) {
    if(!client.historyData) {
        let clientContext = JSON.parse(client.ClientContext), meetingContext = JSON.parse(client.MeetingContext);
        let geolocation;
        if(window.LivingScript_0070 &&
            window.LivingScript_0070.globalContext &&
            window.LivingScript_0070.globalContext.geolocation) {
            geolocation = window.LivingScript_0070.globalContext.geolocation;
        }

        client.historyData ={
            GrantID: clientContext.GrantID,
            FromPE: clientContext.FromPE,
            ByPE: clientContext.ByPE,
            ToPE: clientContext.ToPE,
            GrantPE: clientContext.GrantPE,
            BeginTime: getCurrentTime(),
            ContainerId: getContainerId(),
            SessionId: getSessionId(),
            ClientId: client.Client,
            ClientParentId: client.ParentClient,
            ClientRootId: client.RootClient,
            MeetingId: client.Meeting,
            MeetingParentId: client.ParentMeeting,
            MeetingRootId: client.RootMeeting,
            MeetingRole: client.MeetingRole,
            MeetingParentRole: meetingContext.MeetingParentRole,
            MeetingPE: meetingContext.MeetingPE,
            SegmentId: getGUID(),
            Lat: geolocation? geolocation.location.latitude: 0,
            Long: geolocation ? geolocation.location.longitude : 0,
            //ClientTemplateId: component.component ? component.component.Template : "",
            //ClientExtendedPath: component.clientNodeId,
            CredentialPE: Cookies.get('credentialPE'),
            //GatewayPE: component.GatewayPE,
            //AgentOfPE: component.AgentOfPE,
            AttributionId: client.Attribution,
            EventTemplate: eventTemplates.clientComponentEvents,
            //EventTemplateFFTTId: this.Y.app.eventTemplates[this.config.eventTemplates.clientComponentEvents].FFTTID,
            //EventTemplateMATId: this.Y.app.eventTemplates[this.config.eventTemplates.clientComponentEvents].MATID,
            Registrant: eventRegistrants.simpliaBrowserApp,
            IPAddress: geolocation ? geolocation.traits.ip_address : '',
            City: geolocation ? geolocation.city.names.en : '',
            State: geolocation ? geolocation.postal.code : '',
            Country: geolocation ? geolocation.country.names.en : '',
            GeoIPLocation: geolocation ? JSON.stringify(geolocation) : ''
        };
    }
}


function getCurrentTime() {
    return (new Date()).toISOString();
}

function getContainerId() {
    return _containerId;
}

function getSessionId() {
    return Cookies.get('sessionId');
}

async function saveAsPDF(iframe_id, filename) {
    let elem = await takeSnapshot(iframe_id);
    makePDF(elem, filename);
}

function isCustomElement(element){
    if(element.tagName.indexOf("-") !== -1) {
        return true;
    }
    let isAttribute = element.getAttribute("is");
    if(isAttribute === null) {
        return false;
    }
    return isAttribute.indexOf("-") !== -1;
}

function getViewportHTML(viewportID, fullHeight) {
    fullHeight = (typeof fullHeight !== "undefined") ? fullHeight : true;
    let termElem = document.getElementById(viewportID).querySelector('div[name="oxygen-termination"]');
    let elem;

    let fixPath = (path)=>{
        return path.endsWith("/") ?
            path.slice(0, -1) :
            ( (path.lastIndexOf("/") !== -1) ? path.slice(0,path.lastIndexOf("/") + 1) : path );
    };

    let getElementHTML = (elem) => {
        let elemRoot = isCustomElement(elem)  ? elem.shadowRoot : elem;
        let inputList = elemRoot.querySelectorAll('input');
        //console.log('getElementHTML', inputList);
        inputList.forEach((e)=>{
            //console.log('getElementHTML', 'setting value:', e.value, ' for:', e);
            e.setAttribute('value', e.value);
        });
        let html = isCustomElement(elem)  ? elem.shadowRoot.innerHTML : elem.innerHTML;
        if(fullHeight) {
            let tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;
            Array.from(tempDiv.children).forEach((e)=>{
                if(e.style['max-height']) {
                    e.style['max-height'] = '';
                }
                if(e.style.height) {
                    e.style.height = '';
                }
            });
            return tempDiv.innerHTML;
        }
        else {
            return html;
        }
    };

    if(termElem && (elem = termElem.children[0])) {
        if(elem.nodeName === 'IFRAME') {
            let path = fixPath(elem.contentWindow.location.pathname);
            elem.contentDocument.body.querySelectorAll('input').forEach((e)=>{
                e.setAttribute('value', e.value);
            });
            return `<html><head><base href="${elem.contentWindow.location.origin}${path}"/>${elem.contentDocument.head.innerHTML}</head><body>${elem.contentDocument.body.innerHTML}</body></html>`;
        }
        else {
            let body = getElementHTML(elem);
            //${window.location.pathname}
            let cssLinks = Array.from(document.querySelectorAll('head > link')).filter(e => e.rel === 'stylesheet').reduce((acc, cur)=>{
                return acc += cur.outerHTML;
            }, "");

            let path = fixPath(window.location.pathname);
            return `<html><head><base href="${window.location.origin}${path}"/>${cssLinks}</head><body>${body}</body></html>`;
        }
    }
    return `<html><head></head><body></body></html>`;
}

async function takeSnapshot(src) {
    if(src.nodeName === 'IFRAME') {
        src = src.contentDocument.body;
    }
    let canvas = await html2canvas(src, {
        allowTaint: true,
        windowWidth: 800,
        proxy: '/DashboardWrapper/System/ServerSide'
    })
    const elem = document.getElementById(pdfOutputDivId);

    document.getElementById(pdfContentImgId).setAttribute('src', canvas.toDataURL());
    return elem;
}

function makePDF(elem, filename) {
    //console.log('makePDF', 'elem:', elem, ' - filename:', filename);
    html2canvas(elem, {
        allowTaint: true,
        windowWidth: 800
      }).then((canvas) => {
        var pdf = new jspdf.jsPDF({
          orientation: 'p',
          unit: 'px',
          format: 'letter',
          putOnlyUsedFonts: true,
          floatPrecision: 16,
          hotfixes: ['px_scaling'],
        });
        for (var i = 0; i <= (elem.clientHeight / 1000); i++) {
          //! This is all just html2canvas stuff
          var srcImg = canvas;
          var sX = 0;
          var sY = 980 * i; // start 980 pixels down for every new page
          var sWidth = 900;
          var sHeight = 1000;
          var dX = 0;
          var dY = 0;
          var dWidth = 900;
          var dHeight = 1000;

          window.onePageCanvas = document.createElement("canvas");
          onePageCanvas.setAttribute('width', 900);
          onePageCanvas.setAttribute('height', 1000);
          var ctx = onePageCanvas.getContext('2d');
          ctx.fillStyle="#FFFFFF";
          ctx.fillRect(0,0, dWidth, dHeight);
          // details on this usage of this function: 
          // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
          ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

          // document.body.appendChild(canvas);
          var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);
          var width = onePageCanvas.width;
          var height = onePageCanvas.clientHeight;

          //! If we're on anything other than the first page,
          // add another page
          if (i > 0) {
            pdf.addPage(); //8.5" x 11" in pts (in*72)
          }
          //! now we declare that we're working on that page
          pdf.setPage(i + 1);
          //! now we add content to that page!
          pdf.addImage(canvasDataURL, 'PNG', 40, 60, width - 80, height);

        }
        //! after the for loop is finished running, we save the pdf.
        //console.log('makePDF', 'saving ', `${filename}.pdf`);
        pdf.save(`${filename}.pdf`);
    });
}

async function getContentTemplates(contextPE) {
    let parsedPE = parseOES(contextPE).segments;
    let contentTemplateType = '_FFFFFFFFFFFFFF00001630603991560578_';
    return await runDBQuery(
        `select distinct GrantRightID, GrantRightDN from OxygenDatabase.Grants where
((ToLeftR = ? AND ToLeftID=? AND ToRightR=? AND ToRightID=?) OR
    (ToLeftR=? AND ToLeftID=? AND ToRightR=? AND ToRightID=?)) and GrantRightMATID like '%${contentTemplateType}%'`,
        [parsedPE[0], parsedPE[1], parsedPE[2], parsedPE[3], parsedPE[2], parsedPE[3], parsedPE[2], parsedPE[3]]
    );
}

function isCredentialAccount(client) {
    return window.LivingScript_0070.context.credentialId  === client.ClientRight;
}

function showWebComponentViewport(parentClientID, parentViewportID, viewportData, extendedTitle, wcURL, wcName, wcProps) {
    wcProps = wcProps || {};

    let terminationID = getGUID();
    let rootMenuItem = addRootMenuItem(extendedTitle, parentClientID, parentViewportID);

    if(rootMenuItem) {
        window.BotServices.PullViewPort(viewportData, rootMenuItem);

        const terminationObj = {
            viewportID: viewportData.viewportID,
            terminationID,
            MediaType : 'WebComponent',
            URL: wcURL,
            Template: wcName,
            dataset: wcProps
        };
        window.BotServices.PullTermination(terminationObj, rootMenuItem);
    }

    return viewportData.viewportID;
}

function addRootMenuItem(extendedPath, parentClientID, parentViewportID) {

    let parentRootMenuItem = window.devStore.rootMenu.find(e => (e.ClientRowID === parentClientID && e.ViewPortID === parentViewportID));
    if(parentRootMenuItem) {
        let childRootMenuItem = Object.assign(
            {},
            parentRootMenuItem,
            { Path: `${parentRootMenuItem.Path}/${extendedPath}`, extendedTitle: extendedPath
            });
        window.devStore.addRootMenu(childRootMenuItem);
        return childRootMenuItem;
    }
}


function showIframeViewport(ClientID, parentViewportID, path, title, frameURL, width, height) {
    let viewportID = getGUID();
    return showWebComponentViewport(
        ClientID,
        parentViewportID,
        {
            viewportID,
            ClientID,
            MeetingPath : '',
            ClientPath : `${ClientID}/${path}`,
            TopLeftX : "0px",
            TopRightY : "0px",
            ZIndex : 1000,
            Width: width || "415px",
            Height: height || "fit-content",
            PanelTitle: title,
            DisplayState: ''
        },
        title,
        '/simplia/dist/builds/iframe-container-wc/app.js',
        'iframe-container',
        {
            frameurl: frameURL
        }
    );
}

function getMainViewportID(meetingId) {
    let viewport;
    return (viewport = window.devStore.viewPorts.find((e)=> {
        return e.ClientPath === `${meetingId}`;
    })) &&  viewport.viewportID;
}

function getMainViewportIDFromClient(clientId) {
    let client;
    if(client = window.devStore.clients.find(e => e.Client === clientId)) {
        return window.devStore.viewPorts.find((e)=> {
            return e.ClientPath === `${client.Meeting}`;
        }).viewportID;
    }
    return false;
}


async function showUploadFilePanel(ClientID, panelTitle, ContextPE, viewportID, callback) {
    let iframeViewportID = showIframeViewport(
        ClientID,
        getMainViewportIDFromClient(ClientID),
        panelTitle,
        panelTitle,
        `/OxygenFileServices-0070/s3.html?${(new URLSearchParams({
            bucket: 'n.simplia.com',
            ContextPE,
            ClientID,
            PanelID: viewportID}))}`
    );

    let viewportContext = getViewportContext(viewportID);

    await viewportContext.viewport.MessageBox.$nextTick();
    await viewportContext.viewport.MessageBox.$nextTick();
    await viewportContext.viewport.MessageBox.$nextTick();

    let counter = 0;
    let intervalID = setInterval(()=>{
        let wc = document.querySelector(`iframe-container[data-viewportID="${iframeViewportID}"]`);
        if(counter > 10) {
            clearInterval(intervalID);
        }
        else if(wc && wc.shadowRoot && wc.shadowRoot.getElementById(wc.id) && wc.shadowRoot.getElementById(wc.id).contentWindow.uppy) {
            wc.shadowRoot.getElementById(wc.id).contentWindow.uppy.on('upload-success', (file, response) => {

                let parsedPE = oxygenUtil.parseOES(ContextPE).segments
                let url = `https://s3.amazonaws.com/n.simplia.com//${parsedPE[1]}/${parsedPE[3]}/${parsedPE[0]}/${parsedPE[2]}/${file.name}`;
                window.DevMgr.closeViewPort(iframeViewportID);
                callback(null, url, file);
            });
            clearInterval(intervalID);
        }
        else {
            counter++;
        }

    },200);

}

async function getComponentFileUrl(componentId) {
    let data = await callCommonServiceAPI(
        "simplia-api",
        "getFileUrls",
        {
            componentId,
            sessionId: Cookies.get('sessionId')
        }
    );
    //console.log('getComponentFileUrl', 'data:', data);

    return data.fileUrl;
}

async function saveFilterSearchData(contextPE, searchPrefix, searchName, searchData, ...args) {
    let saveId, parsedContextPE = parseOES(contextPE).segments;
    if(args.length > 1) {
        saveId = args[0];
    }
    if(!saveId) {
        saveId = `OxygenSavedSearch_${parsedContextPE[0]}_${parsedContextPE[2]}_${searchPrefix}_${getUUID()}`;
    }

    return await updateComponentEx(
        parsedContextPE[1],
        {
            'set': {
                [saveId]: {
                    searchName,
                    searchData
                }
            },
            Edge: parsedContextPE[3]
        }
    );
}

async function deleteSavedSearch(contextPE, saveId) {
    let parsedContextPE = parseOES(contextPE).segments;

    return await updateComponentEx(
        parsedContextPE[1],
        {
            'remove':  { [saveId]: '' },
            Edge: parsedContextPE[3]
        }
    );
}

async function getFilterSearchData(contextPE, searchPrefix) {
    let parsedContextPE = parseOES(contextPE).segments;
    try {
        let componentItem = await getComponent(parsedContextPE[1], parsedContextPE[3]);
        return Object.keys(componentItem).filter(e => e.startsWith(`OxygenSavedSearch_${parsedContextPE[0]}_${parsedContextPE[2]}_${searchPrefix}`)).map((e)=>{

            return {
                saveId: e,
                name: componentItem[e].searchName,
                data: componentItem[e].searchData
            };
        })
    }
    catch(e) {
        return null;
    }
}

async function updateComponentEx(componentId, values, ...args) {
    let params = {
        componentId,
        values,
        update: !!args[0]
    };

    return await callCommonServiceAPI(
        "simplia-api",
        "updateComponentEx",
        params
    );
}

function setAttachmentClipboard(clipboardData) {
    window._attachmentClipboard = clipboardData;

    let event = new CustomEvent(`attachment-clipboard-updated`, {
        detail: {
            ClipboardData: clipboardData
        }
    });
    //console.log('setAttachmentClipboard', 'event:', event);
    window.dispatchEvent(event);
}

function setAttachmentClipboardCallback(id, fn) {
    if(!window._attachmentClipboardCallbacks) {
        window._attachmentClipboardCallbacks = {};
    }
    window._attachmentClipboardCallbacks[id] = fn;
    //console.log('setAttachmentClipboardCallback', 'cbs:', window._attachmentClipboardCallbacks);
}

function unsetAttachmentClipboardCallback(id) {
    delete window._attachmentClipboardCallbacks[id];
}

function isDate(date) {
    return date instanceof Date && !isNaN(date.valueOf())
}

/*
if(!window.oxygenUtil || !window.oxygenUtil.defaultSegmentEncoding) {
    window.oxygenUtil = this;
}

 */

if(!window._cb_cb_set) {
    if(!window._attachmentClipboardCallbacks) {
        window._attachmentClipboardCallbacks = {};
    }

    //console.log('oxygenutil', 'adding clipboard event listener');
    window.addEventListener(`attachment-clipboard-updated`, (ev)=>{
        //console.log('attachment-clipboard-updated-callback', 'event:', ev, ' - cbs:', window._attachmentClipboardCallbacks);
        Object.values(window._attachmentClipboardCallbacks).forEach((e)=>{
            //console.log('attachment-clipboard-updated-callback', 'param:', ev.detail);
            e(ev.detail);
        });
    });
    window._cb_cb_set = true;
}

var deskNavigationList = [], currentNavigationIndex = -1;

/*
function addDeskNavigation(originDeskClientID, clientID, deskID, pullPEParams) {
    let currentTree = deskNavigationList[originDeskClientID] || (deskNavigationList[originDeskClientID] = { navArray: [], currentIndex: -1 });
    currentTree[originDeskClientID].currentIndex = currentTree.navArray.push({ clientID, deskID, pullPEParams }) - 1;
}

 */
function addDeskNavigation(deskId, pullPEParams) {
    currentNavigationIndex = deskNavigationList.push({deskId, pullPEParams}) - 1;
}

function setCurrentNavigationIndex(newVal) {
    currentNavigationIndex = newVal;
}


const eventTypeIconMap = {
    "Share": "fal fa-share-alt-square",
    "MakeCall": "fal fa-phone-plus",
    "DialerCallEvents": "fal fa-phone-plus",
    "BehaviourStates": "fal fa-gears",
    "LinkCreated": "fal fa-link",
    "Email": "fal fa-at",
    "Call/Start": "fal fa-phone-plus",
    "Call/Received": "fal fa-phone-plus",
    "Call/Recording": "fas fa-play",
    "Workflow": "fal fa-chart-user",
    "MeetingEvents": "fal fa-handshake",
    "Segment": "fal fa-chart-pie-simple",
    "BasicCallAlert": "fal fa-bell",
    "Send": "fal fa-paper-plane",
    "Delivery": "fal fa-truck",
    "Open": "fal fa-door-open",
    "BehaviourEvent": "fal fa-gears",
    "CALL/START": "fal fa-phone-plus",
    "Leto App Events": "fal fa-browser",
    "CALL/DIAL": "fal fa-phone-plus",
    "Offer": "fal fa-hand-holding fa-rotate-180",
    "Message": "fal fa-message",
    "SMS/Received": "fal fa-message-sms",
    "Bounce": "fal fa-arrow-up-from-arc",
    "CreateComponent": "fal fa-layer-plus",
    "SMS/START": "fal fa-message-sms",
    "VoiceMail": "fal fa-voicemail",
    "ManualCallEvents": "fal fa-phone-plus",
    "SMS/SEND": "fal fa-message-sms",
    "VoiceMail/Received": "fal fa-voicemail",
    "SMS/FAILED": "fal fa-message-sms",
    "Request Contact": "fal fa-hand-holding",
    "Shared Schedule": "fal fa-calendar",
    "default": "fal fa-circle-info",
    "__playEvent__": "fas fa-play",
    "__stopIcon__": "fas fa-stop",
    "__pauseIcon__": "fas fa-pause",
    "__xlsxIcon__": "fal fa-file-excel"
};

function getRowLocalFilterDataForES(PE,
                                    {sortDir, embeddedFilter, embeddedList, reachableFilter, subjectTemplateFilter, subjectTemplate, hasSubjectTemplateAdmin,
                                        historyFilter, accountFilter, eventFilter, replyFilter, conversationsFilter, conversationThreadId, mode, deskId,
                                    causingEventFilter, causingEventId})  {
    //console.log('getRowLocalFilterDataForES', 'conversationThreadId:', conversationThreadId);
    let parsedPE = oxygenUtil.parseOES(PE).segments;
    //console.log('getRowLocalFilterDataForES', 'mode:', mode, ' deskId:', deskId);

    if(mode === 'desk') {
        return {
            DeskFilterClause: [
                { name: 'HistoryItem.DeskID', keyword: true, value: deskId, like: false, notNull: false },
                { name: 'HistoryItem.ToDeskID', keyword: true, value: deskId, like: false, notNull: false }
            ]
        }
    }
    else {
        return {
            leftR: parsedPE[0],
            leftID: parsedPE[1],
            rightR: parsedPE[2],
            rightID: parsedPE[3],
            GrantPE: PE,
            sortDir: sortDir,
            SubjectTemplateFilterClause: (!subjectTemplateFilter) ? [] :
                [
                    { name: 'HistoryItem.ByRightFFTTId', keyword: false, value: subjectTemplate, like: true, notNull: false },
                    { name: 'HistoryItem.ToRightFFTTid', keyword: false, value: subjectTemplate, like: true, notNull: false }
                ],
            EmbeddedFilterClause: (!embeddedFilter) ? [] : ( !embeddedList.length ?
                [{ name: 'ToPE', value: '\x7F', like: false, notNull: false}] : embeddedList.reduce((acc, cur)=>{
                    oxygenUtil.getAdjustedCurrentPEs(cur.PE).forEach((e)=>{
                        acc.push({ name: 'ToPE', value: e, like: false, notNull: false});
                        acc.push({ name: 'ByPE', value: e, like: false, notNull: false});
                    });
                    return acc;
                },[]) ),
            ReachableContextPEClause: ( !reachableFilter || historyFilter || embeddedFilter || subjectTemplateFilter ) ? [] : oxygenUtil.getAdjustedCurrentPEs(PE).map((e)=>{
                return { name: 'ReachableContextPE', value: e, like: false, notNull: false};
            }),
            ContextPEClause: ( embeddedFilter || historyFilter || hasSubjectTemplateAdmin) ? [] : [].concat(/*oxygenUtil.getAdjustedCurrentPEs(PE).map((e)=>{
            return { name: 'ContextPE', value: e, like: false, notNull: false, keyword: true};

        }),*/oxygenUtil.getAdjustedCurrentPEs(PE).map((e)=>{
                return { name: 'ContextPE', value: e, like: false, notNull: false};

            }) ),
            AccountFilterClause: accountFilter.accountPEs.map((e)=>{
                return { name: 'AgentOfPE', value: e, like: false, notNull: false};

            }),
            EventFilterClause: !eventFilter ? {} : ( eventFilter === 'sent' ?
                {  script: "return doc['ByPE'].value == doc['ReachableContextPE'].value;"} :
                { script: "return ( doc['ByPE'].value != doc['ReachableContextPE'].value);"} ),// || (doc['ToPE'].value == doc['ReachableContextPE'].value) || (doc['GrantPE'].value == doc['ReachableContextPE'].value);"} ),
            ReplyFilterClause: !replyFilter ? {} :
                {  script: "return ( ( doc['ByPE'].value != doc['ReachableContextPE'].value) && ( doc['HistoryItem.CausingEventID.keyword'].value != doc['SourcePK'].value )) ;"},
            ConversationStarterClause: !conversationsFilter ? {} :
                { script: "return doc['SourcePK'].value == doc['HistoryItem.CausingEventID.keyword'].value;"},
            ConversationThreadClause: conversationThreadId ? [ { name: 'HistoryItem.RootEventID', keyword: true, value: conversationThreadId, like: false, notNull: false }] : [],
            CausingEventClause: causingEventFilter ? [ { name: 'HistoryItem.CausingEventID', keyword: true, value: causingEventId } ] : [],
            CausingEventScriptClause: causingEventFilter ? {  script: "return doc['HistoryItem.CausingEventID.keyword'].value != doc['SourcePK'].value;"} : {}

            //sharedTableThread: sharedTablePE.value ? oxygenUtil.parseOES(sharedTablePE.value).segments[1] : '',
            //sharedTablePE: sharedTablePE.value || ''
        };
    }
}

async function getInboxData(
    PE,
    searchText,
    selectedDir,
    selectedCats,
    selectedRangeValues,
    selectedSortByValues,
    additionalParams,

    {noLimit, sortDir, embeddedFilter, embeddedList, reachableFilter, historyFilter, accountFilter, eventFilter, replyFilter, conversationFilters, conversationsFilter,
        conversationThreadId, ClientId, GrantID, currentSearchMode, queryDataWindowSize, limitStartIndex, cur_scroll_id, prev_scroll_id, unique_id, mode, deskId,
        subjectTemplateFilter, subjectTemplate, hasSubjectTemplateAdmin, causingEventFilter, causingEventId}) {

    selectedDir = selectedDir || {
        name: 'All',
        selected: true,
        defaultTimeSortField: 'CreationTime',
        categories: [],
        fields: [],
        searchCriteria: []
    };

    selectedCats = selectedCats || [{ name: 'All'}];

    selectedRangeValues = selectedRangeValues || [];
    selectedSortByValues = selectedSortByValues || [];
    additionalParams = additionalParams || {};

    noLimit = (typeof noLimit !== "undefined") ? noLimit : false;
    sortDir = sortDir || 'desc';
    embeddedFilter = (typeof embeddedFilter !== "undefined") ? embeddedFilter : false;
    reachableFilter = (typeof reachableFilter !== "undefined") ? reachableFilter : false;
    historyFilter = (typeof historyFilter !== "undefined") ? historyFilter : false;
    accountFilter = accountFilter || { accountPEs: [] };
    eventFilter = (typeof eventFilter !== "undefined") ? eventFilter : false;
    conversationsFilter = (typeof conversationsFilter !== "undefined") ? conversationsFilter : false;
    replyFilter = (typeof replyFilter !== "undefined") ? replyFilter : false;
    subjectTemplateFilter = (typeof subjectTemplateFilter !== "undefined") ? subjectTemplateFilter : false;
    hasSubjectTemplateAdmin = (typeof hasSubjectTemplateAdmin !== "undefined") ? hasSubjectTemplateAdmin : false;
    causingEventFilter = (typeof causingEventFilter !== "undefined") ? causingEventFilter : false;

    conversationFilters = conversationFilters || [];
    currentSearchMode = currentSearchMode || 'most_recent';
    queryDataWindowSize = queryDataWindowSize || 25;
    limitStartIndex = (typeof limitStartIndex !== "undefined") ? limitStartIndex : 0;
    cur_scroll_id = (typeof cur_scroll_id !== "undefined") ? cur_scroll_id : '';




    //console.log('getInboxData', 'args:', arguments);

    //console.log('selectedDir:', selectedDir);
    let catFilter = selectedCats.reduce((acc, cur)=>{
        if(cur.fieldName) {
            if (!Array.isArray(cur.fieldName)) {
                cur.fieldName = [cur.fieldName];
            }
            if (!Array.isArray(cur.fieldValue)) {
                cur.fieldValue = [cur.fieldValue];
            }
            if (!Array.isArray(cur.like)) {
                cur.like = cur.fieldValue.map(e => !!(cur.like));
            }
            if (!Array.isArray(cur.notNull)) {
                cur.notNull = cur.fieldValue.map(e => !!(cur.notNull));
            }

            let arr = cur.fieldName.map((e, index) => {
                return {
                    keyword: !!cur.keyword,
                    name: `${(cur.fieldPrefix || '')}${e}`,
                    value: cur.fieldValue[index],
                    like: !!cur.like[index],
                    notNull: !!cur.notNull[index]
                };
            });
            acc.push(arr);
        }
        return acc;
    }, []);

    let where = selectedCats.reduce((acc, cur)=>{
        let w;
        if(w = cur.whereFilter) {
            if (!Array.isArray(w.fieldName)) {
                w.fieldName = [w.fieldName];
            }
            if (!Array.isArray(w.fieldValue)) {
                w.fieldValue = [w.fieldValue];
            }
            if(!Array.isArray(w.like)) {
                w.like = w.fieldValue.map(e => !!(w.like));
            }
            if(!Array.isArray(w.notNull)) {
                w.notNull = w.fieldValue.map(e => !!(w.notNull));
            }
            let arr = w.fieldName.map((e, index) => {
                return {

                    name: `${(cur.fieldPrefix || '')}${e}`,
                    value: w.fieldValue[index],
                    like: !!w.like[index],
                    notNull: !!w.notNull[index]
                }
            });
            acc.push(...arr);
        }
        return acc;
    }, []).filter( e => e.name && e.value );

    if(searchText && searchText.value) {

        where.push({all: true, value: searchText.value});
    }

    //if(historyFilter.value) {
    if(historyFilter) {
        //console.log('adding searchText:', 'historyFilter.value:', historyFilter);
        where.push({all: true, value: oxygenUtil.parseOES(PE).segments[2]});
    }

    let tableQuery, tableQueryFilterValues =
            getRowLocalFilterDataForES(PE,
                {sortDir, embeddedFilter, embeddedList, reachableFilter, historyFilter, accountFilter, eventFilter, replyFilter, conversationsFilter, conversationThreadId, mode, deskId,
                    subjectTemplateFilter, subjectTemplate, hasSubjectTemplateAdmin, causingEventFilter, causingEventId}),
        tableQueryFilterValuesObj = {}, tableQueryFilterValuesArray = [];

    tableQueryFilterValues['catFilter'] = '{{catFilter}}';

    if(additionalParams && additionalParams.where) {
        additionalParams.where = !Array.isArray(additionalParams.where) ? [additionalParams.where] : additionalParams.where;
        where.push(...additionalParams.where)
    }

    let ORFilter = [];
    if(conversationFilters) {
        ORFilter.push(...conversationFilters);
    }

    let scripts = [];
    //console.log('tableQueryFilterValues:', tableQueryFilterValues);
    Object.entries(tableQueryFilterValues).forEach((e)=>{
        if(((e[0] === 'ReachableContextPEClause') || (e[0] === "ContextPEClause") || (e[0] === "DeskFilterClause") ||
        (e[0] === 'AccountFilterClause') || (e[0] === 'EmbeddedFilterClause') || (e[0] === "ConversationThreadClause")
        || (e[0] === 'SubjectTemplateFilterClause') || (e[0] === 'CausingEventClause') ) && Array.isArray(e[1]) && e[1].length  ) {
            catFilter.push(e[1]);
        }
        if(e[0] === 'EventFilterClause') {
            if(e[1] && !oxygenUtil.isEmptyObject(e[1])) {
                scripts.push(e[1]);
            }
        }
        if(e[0] === 'ReplyFilterClause') {
            if(e[1] && !oxygenUtil.isEmptyObject(e[1])) {
                scripts.push(e[1]);
            }
        }
        if(e[0] === 'ConversationStarterClause') {
            if(e[1] && !oxygenUtil.isEmptyObject(e[1])) {
                scripts.push(e[1]);
            }
        }
        if(e[0] === 'CausingEventScriptClause') {
            if(e[1] && !oxygenUtil.isEmptyObject(e[1])) {
                scripts.push(e[1]);
            }
        }
    });

    let timeSort = false;
    let sort = selectedSortByValues.map((field)=>{
        let extraParams = {};
        if(selectedDir.defaultTimeSortField.includes(field.name)) {
            timeSort = true;
            extraParams = {unmapped_type: 'date'};
        }
        return Object.assign({ name: field.name,  order: field.sortDirection}, extraParams);
    });
    if(!timeSort) {
        let field = Array.isArray(selectedDir.defaultTimeSortField) ?  selectedDir.defaultTimeSortField : [selectedDir.defaultTimeSortField];
        selectedDir.defaultTimeSortField.forEach((e)=>{
            sort.push({ name: e,  order: sortDir, unmapped_type: 'date'})
        })

        //sort.push(`${selectedDir.defaultTimeSortField} ${this.currentPanel.localData.sortDir}`);
    }

    let range = selectedRangeValues.reduce((acc, range)=>{
        if(range.rangeType === 'radio') {
            acc.push({ exactMatch: true, value1: range.value, name: range.name});
            if(range.additional) {
                acc.push(...range.additional.map((e)=>{
                    return { value1: e.value, name: e.name}
                }));
            }

        }
        else {
            acc.push({ value1: range.cols[1].value, value2: range.cols[2].value, name: range.name, name1: range.name1, name2: range.name2, oesMatch: range.oesMatch });
        }
        return acc;
    }, []);


    if(Array.isArray(selectedDir.searchCriteria)) {
        selectedDir.searchCriteria.forEach((e)=>{
            where.push({name: e.fieldName, value: e.fieldValue});
        });
    }



    if(additionalParams && additionalParams.range) {
        additionalParams.range = Array.isArray(additionalParams.range) ? additionalParams.range : [additionalParams.range];
        range.push(...additionalParams.range);
    }



    //console.log('getData', 'register');
    let registerVal;
    //console.log('getData', 'limitStartIndex:', searchCoord.value.limitStartIndex);
    if(registerVal = await historyLib.register(
        {
            ClientId,
            GrantID
        })) {

        //console.log('getInboxData', 'registerVal:', registerVal);

        let {results, scroll_id} = await historyLib.action(Object.assign({
            ESIndex: (historyFilter || embeddedFilter )? 'oxygen-history*' : '',
            ClientId,
            GrantID,
            Path: 'History/Search',
            where,
            range,
            scripts: scripts.length ? scripts : '',
            catFilter,
            sort,
            ORFilter

        }, (noLimit ) ? { noLimit: true } : {
            //size: (searchCoord.value.advancedOptions && searchCoord.value.advancedOptions.searchResults)  ? searchCoord.value.advancedOptions.searchResults : queryDataWindowSize.value,
            size: (currentSearchMode === 'latest_events') ? 100 : queryDataWindowSize,
            //from: limitStartIndex}));
            prev_scroll_id,
            scroll_id: cur_scroll_id}, (currentSearchMode === 'latest_events') ? { latestEvents: {
            enable: (currentSearchMode === 'latest_events'),
            collapseField: (historyFilter || embeddedFilter) ? "PK" : "ReachableContextPE" } } : /*{ latestEvents: {
                collapseField: "SourcePK",
                enable: true
            }}*/ {}
        ));
        //console.log('getData', 'results:', results);


        //searchCoord.value.resetTable();
        //results.forEach(e => addHistoryRow(e));
        return { results, scroll_id };
    }
}

async function getHistoryItem(PK, ClientId, GrantID) {
    let registerVal;
    if(registerVal = await historyLib.register(
        {
            ClientId,
            GrantID
        })) {

        //console.log('getInboxData', 'registerVal:', registerVal);

        let {results} = await historyLib.action({
                ESIndex: 'oxygen-history*',
                ClientId,
                GrantID,
                Path: 'History/Search',
                where: [
                    {name: 'PK', value: PK}
                ],
                noLimit: true
            });
        //console.log('getData', 'results:', results);


        //searchCoord.value.resetTable();
        //results.forEach(e => addHistoryRow(e));
        return results.length ? results[0] : null;
    }
}

function getHistoryLocationData() {
    let geolocation = window.LivingScript_0070.globalContext.geolocation
    return (geolocation && geolocation.location) ? {
        Lat: geolocation.location.latitude,
        Long: geolocation.location.longitude,
        IPAddress: geolocation.traits.ip_address,
        City: geolocation.city.names.en,
        State: geolocation.postal.code,
        Country: geolocation.country.names.en,
        GeoIPLocation: JSON.stringify(geolocation)
    } : {};
}

function createOxygenAIMessage(content, role) {
    return {
        role: role || "user",
        content
    }
}

async function postOxygenAI(messages) {
    let url = ` /OxygenAI/OpenAI/CreateChatCompletion`, data = { messages };
    let response = await fetch(
        url,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            mode: 'cors'
        }
    ).catch((e)=> {
        throw e;
    });

    let responseObj;
    if(response.ok && (responseObj = await response.json())) {
        return responseObj;
    }
    return false;
}

async function getGDN(OxygenID) {
    return await callCommonServiceAPI('gdn', 'getGDN', { OxygenID });
}

function getAvailabilityRanges(availabilityString) {
    let ranges = [];
    let minutes = availabilityString.split(" ");
    let start = parseInt(minutes[0]), last = parseInt(minutes[0]);
    for(let i = 1; i < minutes.length - 1; i++) {
        let next = parseInt(minutes[i]);
        if(last + 60 !== next) {
            ranges.push({t1: start * 1000, t1Date: new Date(start * 1000), t2: (last + 60) * 1000, t2Date: new Date( (last + 60) * 1000)});
            start = last = next;
        }
        else {
            last = next;
        }
    }

    ranges.push({t1: start * 1000, t1Date: new Date(start * 1000), t2: (last + 60) * 1000, t2Date: new Date( (last + 60) * 1000)});
    return ranges;

}

function createSimpleDate(date, noYear) {
    noYear = (typeof noYear !== "undefined") ? noYear : false;
    return `${date.getMonth() + 1}/${date.getDate()}${ !noYear  ? `/${date.getFullYear()}` : ''}`
}

function createSimpleTime(date) {
    let hour = date.getHours(), meridiemVal = 'am';
    if(!hour) {
        hour = 12;
    }
    else if(hour > 12) {
        hour -= 12;
        meridiemVal = 'pm';
    }
    return `${hour}:`+ `${date.getMinutes()}`.padStart(2,'0') + `${meridiemVal}`;
}

function addLeftMenu(viewportID, options) {
    let viewport = window.top.devStore.viewPorts.find(e => e.viewportID === viewportID), existingMenuOptions;
    if(viewport && (existingMenuOptions = viewport.leftMenu.options)) {
        //console.log('existingMenuOptions:', existingMenuOptions);
        //console.log('newOption:', Object.assign(options, { path: `${existingMenuOptions.length}`.padStart(3, '0') + `-${options.title}`}));
        existingMenuOptions.push(Object.assign(options, { path: `${existingMenuOptions.length}`.padStart(3, '0') + `-${options.title}`}));
        //console.log('viewport.leftMenu.options:', viewport.leftMenu.options);
    }
}

function setupViewportFnCallListener(viewportId, ref) {
    window.top.addEventListener(`${viewportId}-fn-call`, async (ev)=>{
        if(ev.detail && ref[ev.detail.fn]) {
            let args = ev.detail.args || [];
            ref[ev.detail.fn](...args);
        }
    });
}

function setupViewportFnCallReturnListener(viewportId, ref) {
    //console.log('setupViewportFnCallReturnListener', 'viewportId:', viewportId,  ' - ref:', ref);
    window.top.addEventListener(`${viewportId}-fn-call-return`, async (ev)=>{
        //console.log('setupViewportFnCallReturnListener-listener', 'ev:', ev);
        //console.log('setupViewportFnCallReturnListener-listener', 'ev:', ev, ' - ev.detail:', ev.detail, 'ref:', ref, ' - ref:', ref[ev.detail.fn]);
        if(ev.detail && ref[ev.detail.fn]) {
            let args = ev.detail.args || [];
            let retData = ref[ev.detail.fn](...args);
            let event = new CustomEvent(`${ev.detail.returnEvent}`, {
                detail: {
                    data: retData
                }
            });
            window.top.dispatchEvent(event);
        }
    });
}

async function callViewportFnWithReturn(viewportId, fn, args) {
    return new Promise((resolve)=>{
        let returnEvent = `id${getUUID()}`;
        console.log('callViewportFnWithReturn-promise', 'viewportId:', viewportId, ' - fn:', fn, ' - args:', args, ' - returnEvent:', returnEvent);
        window.top.addEventListener(returnEvent, async (ev)=>{
            console.log('callViewportFnWithReturn-listener', 'ev.detail:', ev.detail);
            resolve(ev.detail.data);
        });

        let ev = new CustomEvent(`${viewportId}-fn-call-return`, {detail: {fn, args, returnEvent}});
        window.top.dispatchEvent(ev);
    });
}

async function callMessageBoxFnWithReturn(viewportId, fn, args) {
    return new Promise((resolve)=>{
        let returnEvent = `id${getUUID()}`;

        window.top.addEventListener(returnEvent, async (ev)=>{
            resolve(ev.detail.data);
        });

        let ev = new CustomEvent(`${viewportId}-messagebox-fn-call-return`, {detail: {fn, args, returnEvent}});
        window.top.dispatchEvent(ev);
    });
}

function setupMessageBoxFnCallReturnListener(viewportId, ref) {
    window.top.addEventListener(`${viewportId}-messagebox-fn-call-return`, async (ev)=>{
        if(ev.detail && ref[ev.detail.fn]) {
            let args = ev.detail.args || [];
            let retData = await ref[ev.detail.fn](...args);
            let event = new CustomEvent(`${ev.detail.returnEvent}`, {
                detail: {
                    data: retData
                }
            });
            window.top.dispatchEvent(event);
        }
    });
}

let readyEvents = {};
function isViewportHeaderFnCallEventReady(viewportId) {
    return readyEvents[`${viewportId}-viewport-header-fn-call-return`];
}

async function callViewportHeaderFnWithReturn(viewportId, fn, args) {
    return new Promise((resolve)=>{
        let returnEvent = `id${getUUID()}`;

        window.top.addEventListener(returnEvent, async (ev)=>{
            resolve(ev.detail.data);
        });

        console.log('callViewportHeaderFnWithReturn', 'sending event viewportId:', viewportId);
        let ev = new CustomEvent(`${viewportId}-viewport-header-fn-call-return`, {detail: {fn, args, returnEvent}});
        window.top.dispatchEvent(ev);
    });
}

function setupViewportHeaderFnCallReturnListener(viewportId, ref) {
    console.log('setupViewportHeaderFnCallReturnListener', 'viewportId:', viewportId, ' - ref:', ref);
    window.top.addEventListener(`${viewportId}-viewport-header-fn-call-return`, async (ev)=>{
        console.log('setupViewportHeaderFnCallReturnListener', 'viewportId:', viewportId, ' - ev:', ev);
        if(ev.detail && ref[ev.detail.fn]) {
            let args = ev.detail.args || [];
            let retData = await ref[ev.detail.fn](...args);
            let event = new CustomEvent(`${ev.detail.returnEvent}`, {
                detail: {
                    data: retData
                }
            });
            window.top.dispatchEvent(event);
        }
    });
    readyEvents[`${viewportId}-viewport-header-fn-call-return`] = 1;
}


async function callViewportComponentFnWithReturn(viewportId, component, fn, args) {
    return new Promise((resolve)=>{
        let returnEvent = `id${getUUID()}`;

        window.top.addEventListener(returnEvent, async (ev)=>{
            resolve(ev.detail.data);
        });

        let ev = new CustomEvent(`${viewportId}-${component}-fn-call-return`, {detail: {fn, args, returnEvent}});
        window.top.dispatchEvent(ev);
    });
}

function setupViewportComponentFnCallReturnListener(viewportId, component, ref) {
    window.top.addEventListener(`${viewportId}-${component}-fn-call-return`, async (ev)=>{
        if(ev.detail && ref[ev.detail.fn]) {
            let args = ev.detail.args || [];
            let retData = await ref[ev.detail.fn](...args);
            let event = new CustomEvent(`${ev.detail.returnEvent}`, {
                detail: {
                    data: retData
                }
            });
            window.top.dispatchEvent(event);
        }
    });
}



function callViewportFn(viewportId, fn, args) {
    let ev = new CustomEvent(`${viewportId}-fn-call`, {detail: {fn, args}});
    window.top.dispatchEvent(ev);
}

function generateMessageBoxDataEvent(viewportId, data) {
    let ev = new CustomEvent(`${viewportId}-messagebox-data`, {detail: data});
    window.top.dispatchEvent(ev);
}

function setupMessageBoxDataEventListener(viewportId, handler) {
    window.top.addEventListener(`${viewportId}-messagebox-data`, async (ev)=>{
        ev.detail && handler && handler(ev.detail);
    });
}

function setMessageBoxComponentData(viewportId, name, value) {
    let ev = new CustomEvent(`${viewportId}-messagebox-set-component-data`, {detail: {name, value}});
    window.top.dispatchEvent(ev);
}

async function getComponentOpenAIDialogues(componentId) {
    return callCommonServiceAPI("simplia-api", "getComponentOpenAIDialogues",{componentId});
}

async function historyBotReply({dataRow, client, viewport}, replyString) {

    let messageBox = viewport.MessageBox, geolocation = window.LivingScript_0070.globalContext.geolocation, PK = getGUID();
    await postHistoryMessage(Object.assign({
        PK,
        ByPE: dataRow.ToPE,
        ToPE: dataRow.ByPE,
        FromPE: messageBox.mainPanel.GatewayPE,
        CredentialPE: messageBox.CredentialPE,
        AgentOfPE: messageBox.mainPanel.AgentOfPE || messageBox.mainPanel.GatewayPE,
        GatewayPE: messageBox.mainPanel.GatewayPE,

        Note: JSON.stringify({ String: replyString, Link: ''}),
        EventLabel: 'BotMessage',
        EventTemplate: '_FFFFFFFFFFFFFF00001575595750910042_',
        TaskID: getGUID(),
        TaskTemplateID: messageBox.standardTaskTemplateId
    }, (client.Attribution ? {
                AttributionId: client.Attribution
            } : {}
        ),
        ( geolocation && geolocation.location) ? {
            Lat: geolocation.location.latitude,
            Long: geolocation.location.longitude,
            IPAddress: geolocation.traits.ip_address,
            City: geolocation.city.names.en,
            State: geolocation.postal.code,
            Country: geolocation.country.names.en,
            GeoIPLocation: JSON.stringify(geolocation),
            SessionId: Cookies.get('sessionId')
        } : {}), client.Client, messageBox.clientContext.GrantID);

    callViewportFn(viewport.viewportID, 'setWaitingMode', [true, PK]);
}

async function historyReply({dataRow, client, viewport, formData}, replyString) {
    //console.log('historyReply', 'formData:', formData);
    let messageBox = viewport.MessageBox, geolocation = window.LivingScript_0070.globalContext.geolocation, PK = getGUID();
    await postHistoryMessage(Object.assign({
            PK,
            ByPE: dataRow.ToPE,
            ToPE: dataRow.ByPE,
            FromPE: messageBox.mainPanel.GatewayPE,
            CredentialPE: messageBox.CredentialPE,
            AgentOfPE: messageBox.mainPanel.AgentOfPE || messageBox.mainPanel.GatewayPE,
            GatewayPE: messageBox.mainPanel.GatewayPE,
            NoteTemplate: "MessageNoteTemplate-0056",
            NoteTemplateID: "_FFFFFFFFFFFFFF00001633127893643639_",
            Note: JSON.stringify({ String: replyString, Link: ''}),
            EventLabel: 'Message',
            EventTemplate: '_FFFFFFFFFFFFFF00001575595750910042_',
            TaskID: getGUID(),
            TaskTemplateID: messageBox.standardTaskTemplateId
        }, (client.Attribution ? {
                AttributionId: client.Attribution
            } : {}
        ),
        ( geolocation && geolocation.location) ? {
            Lat: geolocation.location.latitude,
            Long: geolocation.location.longitude,
            IPAddress: geolocation.traits.ip_address,
            City: geolocation.city.names.en,
            State: geolocation.postal.code,
            Country: geolocation.country.names.en,
            GeoIPLocation: JSON.stringify(geolocation),
            SessionId: Cookies.get('sessionId')
        } : {}), client.Client, messageBox.clientContext.GrantID);

    callViewportFn(viewport.viewportID, 'setWaitingMode', [true, PK]);
}



async function connectPE({dataRow, client, viewport}, PE, userMessage, PEMessage) {
    //console.log('dataRow:', dataRow, ' client:', client, ' - viewport:', viewport);
    let messageBox = viewport.MessageBox, geolocation = window.LivingScript_0070.globalContext.geolocation;
    await async.parallel([
        async () => {
            viewport.MessageBox.removeAllRecipients();
            viewport.MessageBox.addRecipientPE(PE);
        },
        async () => {
            await postHistoryMessage(Object.assign({
                    PK: getGUID(),
                    ByPE: dataRow.ToPE,
                    ToPE: dataRow.ByPE,
                    FromPE: messageBox.mainPanel.GatewayPE,
                    CredentialPE: messageBox.CredentialPE,
                    AgentOfPE: messageBox.mainPanel.AgentOfPE || messageBox.mainPanel.GatewayPE,
                    GatewayPE: messageBox.mainPanel.GatewayPE,
                    NoteTemplate: "MessageNoteTemplate-0056",
                    NoteTemplateID: "_FFFFFFFFFFFFFF00001633127893643639_",

                    Note: JSON.stringify({ String: userMessage, Link: ''}),
                    EventLabel: 'Message',
                    EventTemplate: '_FFFFFFFFFFFFFF00001575595750910042_',
                    TaskID: getGUID(),
                    TaskTemplateID: messageBox.standardTaskTemplateId
                }, (client.Attribution ? {
                        AttributionId: client.Attribution
                    } : {}
                ),
                ( geolocation && geolocation.location) ? {
                    Lat: geolocation.location.latitude,
                    Long: geolocation.location.longitude,
                    IPAddress: geolocation.traits.ip_address,
                    City: geolocation.city.names.en,
                    State: geolocation.postal.code,
                    Country: geolocation.country.names.en,
                    GeoIPLocation: JSON.stringify(geolocation),
                    SessionId: Cookies.get('sessionId')
                } : {}), client.Client, messageBox.clientContext.GrantID);
        },
        async () => {
            await postHistoryMessage(Object.assign({
                    PK: getGUID(),
                    ByPE: dataRow.ByPE,
                    ToPE: PE,
                    FromPE: messageBox.mainPanel.GatewayPE,
                    CredentialPE: messageBox.CredentialPE,
                    AgentOfPE: messageBox.mainPanel.AgentOfPE || messageBox.mainPanel.GatewayPE,
                    GatewayPE: messageBox.mainPanel.GatewayPE,

                    Note: JSON.stringify({ String: PEMessage, Link: ''}),
                    EventLabel: 'Message',
                    EventTemplate: '_FFFFFFFFFFFFFF00001575595750910042_',
                    NoteTemplate: "MessageNoteTemplate-0056",
                    NoteTemplateID: "_FFFFFFFFFFFFFF00001633127893643639_",

                    TaskID: getGUID(),
                    TaskTemplateID: messageBox.standardTaskTemplateId
                }, (client.Attribution ? {
                        AttributionId: client.Attribution
                    } : {}
                ),
                ( geolocation && geolocation.location) ? {
                    Lat: geolocation.location.latitude,
                    Long: geolocation.location.longitude,
                    IPAddress: geolocation.traits.ip_address,
                    City: geolocation.city.names.en,
                    State: geolocation.postal.code,
                    Country: geolocation.country.names.en,
                    GeoIPLocation: JSON.stringify(geolocation),
                    SessionId: Cookies.get('sessionId')
                } : {}), client.Client, messageBox.clientContext.GrantID);
        }
    ])
}

async function checkGrantPath(initPE, terminalPE) {
    let parsedInitPE = parseOES(initPE).segments, parsedTerminalPE = parseOES(terminalPE);
    return await runDBQuery(
        `SELECT * FROM OxygenDatabase.CachedGrantPaths WHERE 
InitialLeftR=? AND InitialLeftID=? AND InitialRightR=? AND InitialRightID=? AND TerminalLeftR=? AND TerminalLeftID=? AND TerminalRightR=? AND TerminalRightID=? LIMIT 1`,
        [...parsedInitPE, ...parsedTerminalPE]
    );
}

async function checkForGrantPE(PE) {
    return await runDBQuery(
        `select * from OxygenDatabase.Grants where GrantPE = ? limit 1`,
        [PE]
    );
}

async function addRecent(PE, leftDN, rightDN) {
    await runDBQuery(
        `insert into OxygenDatabase.Recent (AttributionID, RecentPE, LeftDN, RightDN, SessionID) values (?,?,?,?,?)`,
        [
            window.LivingScript_0070.context.attribution.find(e => e.AccountType === 'PrimaryAccount').ID,
            PE,
            leftDN,
            rightDN,
            window.LivingScript_0070.context.sessionId
        ]
    );
    sendRecentUpdateEvent();
}

function sendRecentUpdateEvent() {
    let event = new CustomEvent(`${window.LivingScript_0070.context.sessionId}-recent-update`, {
        detail: {
        }
    });
    window.dispatchEvent(event);
}

var RO, ROMap = new Map();
function createResizeObserver() {
    RO = new ResizeObserver((entries)=>{
        for(const entry of entries) {
            if(ROMap.has(entry.target)) {
                ROMap.get(entry.target)();
            }
        }
    });
}
createResizeObserver();

var MO, MOMap = new Map();
function createMutationObserver() {
    MO = new MutationObserver((entries)=>{
        for(const entry of entries) {
            if(MOMap.has(entry.target)) {
                MOMap.get(entry.target)(entry);
            }
        }
    });
}
createMutationObserver();

var currentTopViewportId = '';
var viewportOrderList = [];
var initialTopZIndex = 90000
var currentTopZIndex = initialTopZIndex;
function putViewportOnTop(newViewportId) {
    //console.log('putViewportOnTop', 'new:', newViewportId, ' - current:', currentTopViewportId, ' - currentTop:',currentTopZIndex);
    if(!newViewportId || (newViewportId === currentTopViewportId)) {
        return;
    }

    let elem;
    if(currentTopViewportId) {

        let currentViewportIndex = viewportOrderList.findIndex((e => (e.id === newViewportId)));
        if(currentViewportIndex != -1) {
            viewportOrderList.splice(currentViewportIndex, 1);
            viewportOrderList = viewportOrderList.map((e,i) => {
                return {id: e.id, index:(initialTopZIndex + i) }
            } );
            currentTopZIndex = initialTopZIndex + viewportOrderList.length;
        }
        viewportOrderList.push({id: currentTopViewportId, index:currentTopZIndex});
        viewportOrderList.forEach((e)=>{
            if(elem = document.getElementById(e.id)) {
                elem.style.zIndex = e.index;
                elem.style.border = '1px solid lightgrey';
            }
        });
    }
    currentTopViewportId = newViewportId;
    if(elem = document.getElementById(currentTopViewportId)) {
        elem.style.zIndex = ++currentTopZIndex;
        elem.style.border = '2px solid red';
    }
}

function generateViewportEvent(event, viewportId, params) {
    let ev = new CustomEvent(`simplia-viewport-events`, {detail: Object.assign({event, viewportId}, params || {})});
    window.dispatchEvent(ev);
}

if(!window._simplia_viewport_events_set) {
    window._simplia_viewport_events_set = true;
    //setupGlobalViewportEventsListener();
}

function setupGlobalViewportEventsListener() {
    //console.log('setupGlobalViewportEventsListener', 'event setup: simplia-viewport-events');
    window.addEventListener(`simplia-viewport-events`, async (ev)=>{
        //console.log('setupGlobalViewportEventsListener-listener:', ev.detail);
        if(ev.detail) {
            let eventMap = {
                'viewportClicked': ()=> { putViewportOnTop(ev.detail.viewportId) }
            }
            eventMap[ev.detail.event] && eventMap[ev.detail.event]();
        }
    });
}

var botConnectTracker =  {};

async function setBotConnectTracker(ClientId, toPE, params) {
    //await doBotConnect(ClientId, toPE, params);

    let parsedAppPE = oxygenUtil.parseOES(toPE).segments;
    //console.log('setBotConnectTracker', 'ClientId:', ClientId, ' - component:', parsedAppPE[3]);
    if(!botConnectTracker[ClientId]) {
        botConnectTracker[ClientId] = {};
    }
    if(!botConnectTracker[ClientId][parsedAppPE[3]]) {
        botConnectTracker[ClientId][parsedAppPE[3]] = true;
        await doBotConnect(ClientId, toPE, params);
        let intervalId = setInterval(async ()=>{
            await doBotConnect(ClientId, toPE, params);
        },10 * 60 * 1000);
    }

}

async function doBotConnect(ClientId, toPE, params) {
    let parsedAppPE = oxygenUtil.parseOES(toPE).segments, clientRight = parsedAppPE[3];
    return new Promise((resolve, reject)=>{
        //console.log('doBotConnect', 'AppId:', parsedAppPE[3]);
        let client = window.devStore.clients.find(e => (e.Client === ClientId));
        console.log('doBotConnect', 'client:', client);
        let meetingContext =  JSON.parse(client.MeetingContext);
        console.log('doBotConnect', 'meetingContext:', meetingContext, ' - parentId:', meetingContext.MeetingParentId);
        window.BotServices.BotConnect({ClientId, ComponentId: parsedAppPE[3],
            InitialContext: Object.assign(
                {
                    CredentialId: window.LivingScript_0070.context.credentialId,
                    ContextURL: window.LivingScript_0070.context.topWindowURL
                }, params || {})}, (error)=>{
            if(!error) {
                let sessionKey = window.BotServices.botManager.getSessionKey(clientRight, meetingContext.MeetingParentId);
                console.log('doBotConnect', 'sessionKey:', sessionKey);

                let session = window.BotServices.botManager.botSessions[sessionKey];
                if(!session) {
                    let intervalId = setInterval(async ()=>{
                        console.log('doBotConnect', 'session:', window.BotServices.botManager.botSessions[sessionKey]);
                        if((session = window.BotServices.botManager.botSessions[sessionKey])) {
                            clearInterval(intervalId);
                            resolve();
                        }
                    }, 1000);
                }
                else {
                    resolve();
                }

            }
            else {
                reject(error);
            }
        });
    });
}

async function doBotReload(ClientId, toPE) {
    if(!botConnectTracker[ClientId]) {
        botConnectTracker[ClientId] = {};
    }

    botConnectTracker[ClientId][toPE] = true;
    let parsedAppPE = oxygenUtil.parseOES(toPE).segments;
    return new Promise((resolve, reject)=>{
        window.BotServices.BotReload({ClientId, ComponentId: parsedAppPE[3]}, (error)=>{
            if(!error) {
                resolve();
            }
            else {
                reject(error);
            }
        });
    });
}

async function createTipFromTemplate(templateId, DNPrefix, toPE, gatewayGrant, byPE, byGrantId) {
    let guid = oxygenUtil.getGUID();
    let templateComponent = await oxygenUtil.getComponent(templateId);
    let component = await oxygenUtil.createComponent(templateId, templateComponent.InitialContext || {},
        {
            Node: guid,
            DNPrefix,
            toPE: getContextPE(toPE)
        });



    let roles = await oxygenUtil.getAllComponentsRoles(
        [
            {
                id: templateId,
                defaultRole: ['admin']
            }
        ],
        [],
        ['owner','admin','holder']);
    if(roles[templateId] && roles[templateId].length) {
        roles[templateId].forEach(async (e)=>{
            await oxygenUtil.callCommonServiceAPI("simplia-api","putGrant", {
                onObject: component.Node,
                onRole: e.role,
                toPE: getContextPE(toPE),
                fromPE: gatewayGrant.GrantPE,
                fromGrantId: gatewayGrant.GrantID,
                byPE,
                byGrantId
            });
        });
    }
    return component;
}

async function createSmartLink(role, component, meetingContext) {

    let payload = {
        onLoad : [{action : {open : true, child : {DisplayName : 'Inbox', Termination : {
                        MediaType : 'WebComponent',
                        URL: '/simplia/dist/builds/inbox-wc/app.js',
                        Template: 'oxygen-inbox',
                        dataset: Object.assign({connectbot: true,initializeconversationmode: true}, meetingContext ? { contactsmode: 'meeting' } : {})
                    }}}}]


    };



    const url = '/BotServices/bot/getSignedUrl';
    try {
        let meetingParams = '';
        if(meetingContext) {

            delete meetingContext.serverConfig;
            delete meetingContext.serverInfo;
            meetingParams = `&MeetingContext=${JSON.stringify(meetingContext)}`;
        }



        let req = await fetch(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                URL: `${window.location.origin}/DeviceManager/index.html?overlay=false${meetingParams}&inbox=true`,
                Role: role,
                Component: component,
                InitialContext: payload,
                URLType: 'SmartNumber'
            })
        });
        //console.log('createDeepLink', 'req:', req);
        let res = await req.json();
        //console.log('createDeepLink', 'res:', res);
        return res.ShortURL;
    }
    catch(e) {
        console.log('createDeepLink', 'Error:', e);
        return '';
    }
}

function sendViewportChangeEvent(viewportId, params) {
    let event = new CustomEvent(`${viewportId}-viewport-change`, {
        detail: Object.assign({
            ViewportID: viewportId
        }, params)
    });
    window.dispatchEvent(event);
}

async function addDynamoDBTableData(tableName, itemData) {
    let params = {
        tableName,
        itemData
    };

    return await callCommonServiceAPI(
        "simplia-api",
        "addDynamoDBTableData",
        params
    );
}

async function addGlobalSchemaItem(Node, MetaType, Description, extraParams) {
    extraParams = extraParams || {};

    return await addDynamoDBTableData(
        "GlobalSchemas",
        {
            Node,
            Edge: "0",
            MetaType,
            Description,
            ...extraParams
        }
    );
}

async function addToRelatedLinkBookmarks(contextPE, GrantPE, GrantID, label, argJSON, type, folderID) {
    await runDBQuery(
        `insert ignore into OxygenDatabase.Favorites (ContextPE, GrantPE, GrantID, Type, Label, FolderID, ArgJSON) values (?,?,?,?,?,?,?)`,
        [contextPE, GrantPE, GrantID, type || 'SmartLink', label, (typeof folderID !== 'undefined') ? folderID : 0, argJSON]
    );
}



async function getRelatedDesks(clientId, PE, filterFn) {
    return new Promise((resolve, reject)=>{
        let parsedPE = parseOES(PE).segments;

        let params = {
            componentId : '_FFFFFFFFFFFFFF00001684433717737647_',

            command : 'GetGraphAll',
            data : {
                Context: JSON.parse(window.botStore.clients.find(e => e.Client === clientId).CurrentContext)

            },
            Type : 'BotApi'
        }

        window.BotServices.callComponent(params, async (error, data)=>{

            if(error) {
                return reject(error);
            }
            else {
                let roles = await getCurrentSubjectRoles(PE);

                let filteredData = data.map((e) => {
                    e._source._score = e._score;
                    return e._source
                }).filter((e) => {

                    if(e.GraphMetaType &&
                        (e.GraphMetaType === 'Node') && ( !filterFn || filterFn(e) ) && isValidRole(roles, e)) {
                        return true;
                    }
                    return false;
                });

                return resolve(filteredData);
            }
        });

    })
}

async function getCurrentSubjectRoles(contextPE) {
    let parsedContextPE = parseOES(contextPE.value).segments;
    let roles = await getAvailableComponentsRoles(
        [
            {
                id: parsedContextPE[3],
                defaultRole: parsedContextPE[2]
            }
        ],
        parsedContextPE[1],
        parsedContextPE[0],
        [],
        []
    );
    return roles[parsedContextPE[3]];
}

function isValidRole(roles, dataRow) {
    //Get all the roles the thread can play in the current subject
    //console.log('isValidRole', 'roles:', roles, ' - role:',dataRow.ContextRightR, ' - val:',roles.some(e => (e.role === dataRow.ContextRightR)));
    return roles.some(e => (e.role === dataRow.ContextRightR));
    //Then restrict the desks to only those roles (ContextRightR)
}

async function addRoleThroughClient(clientId, role) {
    let client = window.devStore.clients.find(e => (e.Client === clientId)), clientContext = client && client.ClientContext && JSON.parse(client.ClientContext);

    if(client && clientContext) {
        return await putGrant({
            onObject: client.ClientRight,
            onRole: role,
            toPE: clientContext.ToPE,
            fromPE: clientContext.FromPE,
            fromGrantId: clientContext.FromGrantID,
            byPE: clientContext.GrantPE,
            byGrantId: clientContext.GrantID,
        });
    }
    return false;
}

export {
    addRoleThroughClient,
    isViewportHeaderFnCallEventReady,
    marked,
    setupViewportComponentFnCallReturnListener,
    callViewportComponentFnWithReturn,
    addToRelatedLinkBookmarks,
    getHistoryLocationData,
    getHistoryItem,
    validator,
    addGlobalSchemaItem,
    addDynamoDBTableData,
    getContextPE,
    sendViewportChangeEvent,
    createSmartLink,
    createTipFromTemplate,
    setupViewportHeaderFnCallReturnListener,
    callViewportHeaderFnWithReturn,
    setBotConnectTracker,
    doBotConnect,
    doBotReload,
    botConnectTracker,
    callMessageBoxFnWithReturn,
    setupMessageBoxFnCallReturnListener,
    setCurrentNavigationIndex,
    getMainViewportIDFromClient,
    generateViewportEvent,
    putViewportOnTop,
    addDeskNavigation,
    deskNavigationList,
    currentNavigationIndex,
    MO,
    MOMap,
    RO,
    ROMap,
    addRecent,
    checkForGrantPE,
    checkGrantPath,
    setMessageBoxComponentData,
    generateMessageBoxDataEvent,
    setupMessageBoxDataEventListener,
    callViewportFnWithReturn,
    setupViewportFnCallReturnListener,
    historyReply,
    connectPE,
    historyBotReply,
    getComponentOpenAIDialogues,
    setupViewportFnCallListener,
    callViewportFn,
    getMultipleComponents,
    addLeftMenu,
    getAttributionBookmarks,
    createSimpleDate,
    createSimpleTime,
    getGDN,
    getInboxData,
    postOxygenAI,
    createOxygenAIMessage,
    eventTypeIconMap,
    callCommonServiceAPI,
    config,
    isDate,
    unsetAttachmentClipboardCallback,
    setAttachmentClipboardCallback,
    setAttachmentClipboard,
    doTrinoQuery,
    showWebComponentViewport,
    deleteSavedSearch,
    getFilterSearchData,
    saveFilterSearchData,
    updateComponentEx,
    showUploadFilePanel,
    Mustache,
    isCustomElement,
    getViewportHTML,
    saveAsPDF,
    takeSnapshot,
    makePDF,
    setHistoryData,
    setupClientEventsListeners,
    eventRegistrants,
    eventTemplates,
    getSessionId,
    getContainerId,
    getCurrentTime,
    formatDN,
    isGUID,
    formatFullDateString,
    formatDateOnly,
    formatExtendedDateOnly,
    formatTimeOnly,
    getFormattedTime,
    createOES,
    createSimpleOES,
    createSimplePartialOES,
    createPartialOES,
    createPartialOESSegments,
    parseOES,
    encodeHTMLEntities,
    escapeHTMLEntities,
    isEmail,
    getGUID,
    tryParseJSON,
    isEmptyObject,
    getUUID,
    getAdjustedCurrentPEs,
    getComponentByDisplayName,
    getAllComponentsRoles,
    getAvailableComponentsRoles,
    runDBQuery,
    getEmailAddressInfo,
    getPhoneInfo,
    getComponent,
    getGrantFromPE,
    getGrant,
    sendEmailMessages,
    sendPhoneMessages,
    doDruidQuery,
    getInputSuggestions,
    initializePropagator,
    publishPropagator,
    callComponent,
    setupPropagator,
    Cookies,
    createGrantPEFromGrant,
    capitalizeFirstLetter,
    appId,
    addTransaction,
    removeTransaction,
    createScriptTag,
    parsePhone,
    parsePhoneNumberFromString,
    createComponent,
    getDNPrefix,
    isValidPhone,
    callServerAPI,
    getViewportContext,
    putOfferGrant,
    putRequestGrant,
    putGrant,
    revokeGrantById,
    recordAnalyticsEvent,
    denyGrant,
    acceptGrant,
    defaultIconColor,
    getAttributionRecord,
    postHistoryMessage,
    postHistoryUpdate,
    postHistoryNote,
    sendPEEmail,
    getContentTemplates,
    isCredentialAccount,
    showIframeViewport,
    getMainViewportID,
    addTemplateSaveOptions,
    getComponentFileUrl,
    sendComponentData,
    getAvailabilityRanges,
    defaultSegmentEncoding,
    getByContext
};
